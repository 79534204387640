import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const modalSize = { height: 85, width: 30 };
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    minHeight: modalSize ? modalSize.height + "vh" : "85vh",
    minWidth: modalSize ? modalSize.width + "vw" : "40vw",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    color: "black",
  },
  "& .MuiPaper-elevation": {
    alignItems: "center",
    minHeight: modalSize ? modalSize.height + 2 + "vh" : "90vh",
    minWidth: modalSize ? modalSize.width + 2 + "vw" : "45vw",
    overflow: "hidden",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[1000],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  children,
  buttonString,
  boxName,
  icon,
  styleButton,
  className,
  modalSize,
  open,
  setOpen,
}) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{
          background: "#007bff",
          color: "white",
          padding: 1,
          fontSize: 14,
          fontWeight: 600,
          borderRadius: 2,
          boxShadow: 3,
          marginTop: 0,
          marginBottom: 0.2,
          marginLeft: 1,
          ":hover": { background: "#006bff" },
        }}
      >
        {icon ? <img className={className} src={icon} alt="" /> : null}
        {buttonString}
      </Button>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {boxName}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </BootstrapDialog>
    </div>
  );
}
