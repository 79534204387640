import { config } from "../utils.js";

export const dashMenus = [
  { key: "bolsaoDashboard", label: "Bolsão", route: "/app/dashboard/bolsao" },
  { key: "sendDashboard", label: "Envios", route: "/app/dashboard" },
  {
    key: "wordCloudDashboard",
    label: "Nuvem de palavras",
    route: "/app/dashboard/tagcloud",
  },
  {
    key: "botWorkFlowDashboard",
    label: "Fluxo do Bot",
    route: "/app/dashboard/milestone",
  },
  {
    key: "interactionDashboard",
    label: "Conversas",
    route: "/app/dashboard/interactions",
  },
  { key: "uraDashboard", label: "Ura", route: "/app/dashboard/ura" },
];

export const hasDashboardItems = dashMenus.some(
  (item) => config.application.menus?.[item.key]
);
