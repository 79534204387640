import addCircle from "../../../images/addCircle.svg";
import { StyledEngineProvider } from "@mui/material";
import RegisterPageModal from "../others/modal";
import ScheduleTable from "../others/table";
import { useState } from "react";
import ScheduleRegister from "../others/weeklySchedule";
import { useEffect } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";

export default function Schedule() {
  useEffect(() => {
    async function loadScreen() {
      await api
        .get("api/espresso/workschedule", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Erro #004: Sem resultado de busca");
          }
          setApiData(res.data);
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }
    loadScreen();
  }, []);

  const [apiData, setApiData] = useState({});

  return (
    <div className="container">
      <div className="d-flex w-100 align-items-center justify-content-between">
        <div className="titlePage">Schedule</div>
        <div className="pe-4">
          <RegisterPageModal
            buttonString="Adicionar"
            boxName="Criar Schedule"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
          >
            <ScheduleRegister />
          </RegisterPageModal>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <ScheduleTable apiData={apiData} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
