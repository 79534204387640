import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import React, { useState } from "react";
import messageStyle from "../../messages/messages.module.css";
import UploadList from "../../../components/uploadList";
import { uploadMediatoBucket, forTheKing } from "../../../services/utils";

export default function MidiaSender() {
  const [upfiles, setUpfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sended, setSended] = useState(false);
  const handleMediaHSM = async (filetoawait) => {
    setLoading(false);
    setSended(false);
    setUpfiles(filetoawait);
  };

  function reload() {
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  async function sendingTo() {
    if (upfiles.length > 0) {
      setLoading(true);
      console.log("upfile: ", upfiles[0]);

      await uploadMediatoBucket(upfiles[0])
        .then((bucketReturn) => {
          const valueURL = window.location.pathname.split("/");
          console.log("bucketReturn: ", bucketReturn);

          const swordHandle = {
            number: valueURL[3],
            origin: valueURL[4],
            interactionId: valueURL[5],
            agentJwt: localStorage.getItem("pcToken"),
            location: bucketReturn.Location,
            name: bucketReturn.key.split("/")[
              bucketReturn.key.split("/").length - 1
            ],
          };

          forTheKing(swordHandle).then((res) => {
            toast.success("Arquivo enviado com sucesso, aguarde");
            setSended(true);
            reload();
          });
        })
        .catch((err) => {
          toast.error("Falha ao enviar Arquivo");
          setSended(false);
        });
    }
  }

  return (
    <div className={messageStyle.midia__upload}>
      <div className={messageStyle.midia__upload__wrapper}>
        <Dropzone
          min-height="0px"
          accept="image/*, audio/mp4, audio/ogg, audio/basic, audio/L24,audio/mp4,audio/mpeg,audio/ogg,audio/vnd.rn-realaudio,audio/3gpp,audio/3gpp2,audio/ac3,audio/webm,audio/amr-nb,audio/amr, video/mp4,video/mpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onDrop={(acceptedFiles, rejectedFiles, event) => {
            handleMediaHSM(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              className={messageStyle.midia__upload__ghistory__dropzone}
              {...getRootProps()}
              isdragactive={isDragActive.toString()}
              isdragreject={isDragReject.toString()}
            >
              <input {...getInputProps()} />
              {isDragActive && !isDragReject && <p>Solte aqui!</p>}
              {isDragActive && isDragReject && (
                <span>Arquivo(s) não suportado(s)</span>
              )}
              {!isDragActive && !isDragReject && (
                <span>Arraste os arquivos ou clique aqui</span>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      <div className={messageStyle.midia__upload__queue}>
        {upfiles.length === 0 ? (
          <p>Aguardando arquivos...</p>
        ) : (
          <UploadList config={{ upfiles, loading, sended }} />
        )}
      </div>

      <button
        disabled={upfiles.length == 0}
        className="btn btn-primary containerBtn-4 mt-3"
      >
        Enviar
      </button>
    </div>
  );
}
