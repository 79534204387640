import { useState, useEffect } from "react";

export default function SetSchedule({
  schedule,
  mapingSchedule,
  setMapingSchedule,
  outerIndex,
  configuration,
}) {
  const [inputSchedule, setInputSchedule] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setInputSchedule({ id: configuration?.workSchedule?._id });
    }, 200);
  }, [configuration]);

  function scheduleChange(value) {
    let _mapingSchedule = mapingSchedule;
    setInputSchedule({ id: value });
    _mapingSchedule[outerIndex] = value;
    setMapingSchedule(_mapingSchedule);
  }

  return (
    <div className="schedule px-3 mt-3">
      <label className="defaultLabel"> Associar Schedule </label>
      <select
        className="select-bootstrap mt-2"
        value={inputSchedule.id}
        onChange={(e) => scheduleChange(e.target.value)}
      >
        <option value={null} hidden>
          {" "}
          Selecione um schedule{" "}
        </option>
        {schedule?.map((selected, scheduleIdx) => (
          <option value={selected._id} key={scheduleIdx}>
            {" "}
            {selected.name}{" "}
          </option>
        ))}
      </select>
    </div>
  );
}
