import axios from "axios";

let apiURL = ''

if (process.env.NODE_ENV === "development") {
  apiURL = process.env.REACT_APP_APIURL
}
else {
  apiURL = `https://${window.location.href.split('/')[2]}`
}


const api = axios.create({
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
