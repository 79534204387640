import { config } from "../utils.js";

export const listMenus = [
  {
    key: "sendList",
    route: "/app/sendlist",
    label: "Lista de envio",
  },
  {
    key: "timedHSM",
    route: "/app/timedHSM",
    label: "HSM Agendados",
  },
];

export const hasListItems = listMenus.some(
  (item) => config.application.menus?.[item.key]
);
