import { config } from "../utils.js";
export const toolsMenus = [
  {
    key: "toolsGeneral",
    route: "/app/tools",
    label: "Gerais",
  },
  {
    key: "blacklist",
    route: "/app/blacklist",
    label: "BlockList",
  },
];

export const hasToolsMenu = toolsMenus.some(
  (item) => config.application?.menus?.[item.key]
);
