import React from 'react';
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/api';
import { toast } from 'react-toastify';
import ModalCall from '../../components/tables/modalOpen';
import TagsEdit from './TagsEdit';
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';

export default function TagsTable({ tags }) {
  const columns = [
    { field: 'word', headerName: 'Palavra', flex: 2 },
    {
      field: 'sentimento',
      headerName: 'Sentimento',
      flex: 1,
      renderCell: (params) => {
        switch(params.value){
          case -1: return (<span>negativo</span>);
          case 0: return (<span>neutro</span>);
          case 1: return (<span>positivo</span>);
          default: return (<span>-</span>);
        }
      }
    },
    {
      field: 'includes',
      headerName: 'Visível',
      align: 'center',
      headerAlign: 'center',
      flex: 0.2,
      renderCell: (params) => (
        <div>
          <Tooltip title={params.value ? "Sim" : "Não"}>
            <span className="table-cell-trucate">
              {params.value ? <VisibilityIcon style={{ color: "#3498db" }}/> : <VisibilityOffIcon style={{ color: "#f39c12" }}/>}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      field: 'alteration',
      headerName: 'Alterações',
      flex: 0.4,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div className="inline_Alt">
          <div onClick={() => {}}>
            <ModalCall
              modalSize={{ height: 40, width: 40 }}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Palavra"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <TagsEdit selected={params.value} />
            </ModalCall>
          </div>
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c"}}
            onClick={() => {
              if (window.confirm('Deseja mesmo Excluir ?')) {
                api
                  .post(`api/database/tag/delete/${params.value._id}`, {}, {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                  })
                  .then((res) => {
                    toast.success('Deletando Palavra Aguarde!');
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);

                    return;
                  })
                  .catch((err) => {
                    toast.error('Erro #003: Falha ao acessar o banco de dados');
                  });
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < tags.length; i++) {
    rows.push({
      id: i + 1,
      alteration: tags[i],
      ...tags[i],
    });
  }

  return (
    <div style={{ height: '110%', width: '98%' }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'word', sort: 'asc' }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
