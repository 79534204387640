import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../components/tables/table.css";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import api from "../../services/api";
import { toast } from "react-toastify";


export default function BlacklistTable({ blacklist }) {
  async function dellNumber(alteration) {
    let converted = encodeURIComponent(alteration.phone)

    await api
      .delete(`api/espresso/blacklist?phone=${converted}&origin=${alteration.origin}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        toast.success("Deletado com Sucesso")
        setTimeout(function () {
          window.location.reload();
        }, 4000);
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  }

  const columns = [
    { field: "origin", headerName: "Ura", flex: 2 },
    { field: "createDate", headerName: "Data", type: "dateTime", flex: 3, hide: true },
    { field: "phone", headerName: "Telefone", flex: 3 },
    {
      field: "alteration",
      headerName: "Alterações",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="inline_Alt">
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c"}} onClick={() => { dellNumber(params.value); }}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  if (!!blacklist) {
    for (let i = 0; i < blacklist.length; i++) {
      rows.push({
        id: i + 1,
        alteration: { phone: blacklist[i].phone, origin: blacklist[i].origin },
        ...blacklist[i],
      });
    }
  }

  return (
    <div style={{ height: '110%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createDate", sort: "desc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
