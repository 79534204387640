import historyStyle from "./history.module.css";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import HistoryTable from "../../components/tables/historyTable";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import { config } from "../../services/utils";
import { toast } from "react-toastify";

export default function History() {
  const [number, setNumber] = useState("");
  const [origin, setOrigin] = useState("");
  const [retornoAPI, setRetornoAPI] = useState([]);

  const loadTables = async () => {
    const res = await api
      .get(`api/espresso/${number}/${origin}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })

      .then((res) => {
        if (res.data.length === 0) {
          toast.error(`O número ${number} não tem registros`);
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha na pesquisa");
      });
    return;
  };

  const cleanSearch = () => {
    setOrigin("");
    setNumber("");
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className="container py-2">
      <div
        className={`${historyStyle.toolBarHistory} pt-2 pb-2 d-flex w-100 justify-content-between`}
      >
        <div className="titlePage">Histórico</div>
        <div className={historyStyle.searchContainer}>
          <label className="defaultLabel me-1">Origem :</label>
          <div className={historyStyle.inputContainer}>
            <select
              className="select-bootstrap"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            >
              <option defaultValue>Selecione a Origem</option>
              {config.fakeConfig.defaultBroker !== "disable"
                ? config.fakeConfig.brokers[
                    config.fakeConfig.defaultBroker
                  ].senders.map((value) => (
                    <option value={value.origin}>{value.origin}</option>
                  ))
                : config.fakeConfig.enabled.map((broker) =>
                    config.fakeConfig.brokers[broker].senders.map((value) => (
                      <option value={value.origin}>{value.origin}</option>
                    ))
                  )}
            </select>
          </div>

          <label className="defaultLabel ms-3 me-1">Número :</label>
          <div className={historyStyle.inputContainer}>
            <input
              className="input-bootstrap"
              type="text"
              value={number}
              id="number"
              required
              placeholder="+5511996385412"
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>

          <div
            className="ms-5"
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} text="Limpar" />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} text="Pesquisar" />
          </div>
        </div>
      </div>
      <div className={historyStyle.bottomBoxHistory}>
        <StyledEngineProvider injectFirst>
          <HistoryTable
            history={retornoAPI}
            styleButton={{ bgcolor: "black", color: "white" }}
          ></HistoryTable>
        </StyledEngineProvider>
      </div>
    </div>
  );
}
