import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { CircularProgress, Grid } from '@material-ui/core';
import { PURECLOUD_URL, config, CRM_URL, defineLoginGenesys } from '../../../services/utils';
import { v4 as UwUid } from 'uuid';
import { AttachFile } from '@mui/icons-material';
import modalHSMStyle from './modalHSM.module.css';
import ParamLoading from '../../../components/loadParams/loadingParams';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ActionBtn from '../../../components/UI/actionbtn';
import TablePreview from '../previewTable/preview';
import * as XLSX from 'xlsx';

export default function ModalHSM() {
  // ---------------- Constantes ----------------- //
  const oneSenderEntry =
    config.fakeConfig.defaultBroker !== 'disable'
      ? config.fakeConfig.brokers[config.fakeConfig.defaultBroker].senders
      : [];

  const [nameMailing, setNameMailing] = useState('');
  const [sender, setSender] = useState({});
  const [queues, setQueues] = useState([]);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [paramsDefined, setParamsDefined] = useState(false);
  const [params, setParams] = useState([]);
  const [excelData, setExcelData] = useState({});
  const [dataParam, setDataParam] = useState({});
  const [open, setOpen] = useState(false);
  const [callAPIOnline, setCallAPIOnline] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [loading, setLoading] = useState(false);

  // if (messages.length == 0 && config.fakeConfig.defaultBroker !== 'disable') {
  //   getMessages(JSON.stringify({ numberSelected: oneSenderEntry[0], broker: config.fakeConfig.defaultBroker }))
  // }
  // --------------------------------------------- //

  // ----------------- onLoad -------------------- //
  const isLoginGenesys = defineLoginGenesys();

  useEffect(() => {
    if (isLoginGenesys) {
      api
        .get(`${PURECLOUD_URL}/users/${localStorage.getItem('userId')}/queues`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
          },
        })
        .then((res) => {
          if (res.data.entities.length == 0) {
            toast.warn('Usuario não possui filas cadastradas');
          } else {
            setQueues(res.data.entities);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.code == 'authentication.required' ||
            error?.response?.data?.code == 'bad.credentials'
          ) {
            toast.warn('Sessão do genesys expirada');
            localStorage.removeItem('pcToken');
            reload(3000);
          } else {
            toast.error('Filas não encontradas');
          }
        });
    } else {
      let queues = api
        .get(`${CRM_URL}/queue`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          setQueues(res.data.results);
          console.log(res);
        });
    }
    if (messages.length == 0 && config.fakeConfig.defaultBroker !== 'disable') {
      getMessages(JSON.stringify({ numberSelected: oneSenderEntry[0], broker: config.fakeConfig.defaultBroker }));
    }
  }, []);

  useEffect(() => {
    if (selectedMessage && excelData && excelData.columns && selectedMessage.parametros) {
      const matchingColumns = Object.keys(selectedMessage.parametros).map((paramKey) => {
        return excelData.columns.find(
          (column) => column.toLowerCase() === selectedMessage.parametros[paramKey].toLowerCase()
        );
      });
      for (const field in matchingColumns) {
        if (matchingColumns[field]) {
          handleParam(parseInt(field) + 1, matchingColumns[field]);
          console.log(field);
          console.log(matchingColumns[field]);
        }
      }
    }
  }, [selectedMessage]);

  // --------------------------------------------- //

  // ----------------- Functions ----------------- //

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
      },
      timer ? timer : 500
    );
  }

  const handleParam = (field, value) => {
    let _dataParam = dataParam;
    if (value.length > 0) _dataParam[field] = value;
    else delete _dataParam[field];
    setDataParam(_dataParam);
    console.log(dataParam);
    setParamsDefined(Object.keys(selectedMessage?.parametros).length == Object.keys(_dataParam).length);
    setForceUpdate(true); // Para o proximo sofredor: Isso é uma maldita gambiarra pro react atualizar os dados do select em tempo real e não esperar o proximo ciclo de atualização, até o presente momento essa bosta de react é movida a gambiarra.
    setTimeout(() => {
      setForceUpdate(false);
    }, 1);
    console.log(paramsDefined);
  };

  async function getMessages(params) {
    try {
      const paramsParsed = JSON.parse(params);
      const numberSelected = paramsParsed.numberSelected;

      const response = await api.get('api/espresso/mensagens/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const messages = response.data.filter((msg) => {
        const selectedNamespaces = '' + numberSelected.namespaces;
        const isNamespaceMatch =
          selectedNamespaces == msg.namespace ||
          numberSelected.namespaces === '*' ||
          (msg.namespace === '*' && msg.broker === paramsParsed.broker);
        return isNamespaceMatch;
      });

      setCallAPIOnline(true);
      setMessages(messages);
    } catch (err) {
      setCallAPIOnline(false);
      if (callAPIOnline) {
        toast.error('Erro #003: Falha no acesso ao banco de dados');
      }
      setTimeout(() => getMessages(params), 20000);
    }
  }

  const fileClick = (e) => {
    document.getElementById('file-input').click();
  };

  const convertToJson = (csv) => {
    var lines = csv.split('\n');

    var result = [];

    var headers = ('' + lines[0]).replace(/\./g, '').split('|');

    for (var i = 1; i < lines.length; i++) {
      var nullline = true;
      var obj = {};
      var currentline = lines[i].split('|');

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
        if (!!currentline[j] && ('' + currentline[j]).length > 0) nullline = false;
      }

      if (!nullline) result.push(obj);
    }
    return result;
  };

  const handleUploadXLSX = (value) => {
    setPage(0);
    let _excelData = excelData;
    const file = value.files[0];

    if (!file) throw new Error('Nenhum arquivo carregado');
    else {
      _excelData.filemailing = file.name;
      setExcelData({ ..._excelData });

      const reader = new FileReader();
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {
          type: 'binary',
          cellText: false,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        Object.keys(ws).forEach(function (s) {
          if (ws[s].t === 'n') {
            ws[s].w = ws[s].v;
          }
        });

        const data = XLSX.utils.sheet_to_csv(ws, {
          FS: '|',
          header: 1,
          raw: false,
          dateNF: 'yyyy-mm-dd hh:mm:ss',
        });

        _excelData.contacts = convertToJson(data);
        _excelData.columns = Object.keys(_excelData.contacts[0]);

        let phoneCol = null;
        let agendaCol = null;
        for (const column of _excelData.columns) {
          phoneCol =
            ('' + column).toLowerCase().includes('telefone') || ('' + column).toLowerCase().includes('phone')
              ? column
              : phoneCol;
          agendaCol =
            ('' + column).toLowerCase().includes('agenda') || ('' + column).toLowerCase() === 'schedule'
              ? column
              : agendaCol;
        }
        for (let line of _excelData.contacts) {
          let hasPhone = false;
          for (let column of _excelData.columns) {
            hasPhone =
              hasPhone ||
              (phoneCol == column &&
                !!line[column] &&
                ('' + line[column]).length > 11 &&
                ('' + line[column]).length < 14);
          }
          line.codecontact = UwUid();
          line.phonecontact = '+' + line[phoneCol];
          line.activecontact = hasPhone;
          line.createdate = new Date();
          line.sent = false;
          line.status = hasPhone ? 'new' : 'error';
          line.detailmensagem = hasPhone ? 'novo' : 'nenhum telefone para enviar.';
        }

        setExcelData({ ..._excelData });

        toast.success(`O arquivo foi carregado!`);
      };
      reader.readAsBinaryString(file);
    }
  };

  function handleMessage(value) {
    setSelectedMessage(value);
    setDataParam({});
    switchLoading(true);
    if (value?.parametros) {
      setParamsDefined(Object.keys(value.parametros).length === 0);
    } else {
      setParamsDefined(true);
    }
  }

  function switchLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function handleSubmit() {
    setLoadingSend(true);
    let _sender = sender;
    if (oneSenderEntry.length === 1) {
      _sender = {
        numberSelected: oneSenderEntry[0],
        broker: config.fakeConfig.defaultBroker,
      };
    }

    let oldPayload = {
      sender: _sender.numberSelected.mediaId,
      senduser: localStorage.getItem('userName'),
      origin: _sender.numberSelected.origin,
      codemailing: UwUid(),
      filemailing: excelData.filemailing,
      namemailing: nameMailing + '_' + new Date().getTime(),
      typemensagem: 'HSM',
      broker: _sender.broker,
      cdmessage: selectedMessage.codemessage,
      body: selectedMessage.body,
      param: null,
      data: {
        namespace: selectedMessage.namespace,
        elementname: selectedMessage.elementname,
        body: selectedMessage.body,
        param: dataParam,
      },
      parametros: selectedMessage?.parametros || {},
      contacts: excelData.contacts,
      columns: excelData.columns,
    };

    api
      .post('api/espresso/mailing', oldPayload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        toast.success('Lista criada com sucesso!');
        if (res.data.ok == 'nok') {
          toast.warn('Um ou mais números encontram-se em atendimento');
        }
        setLoadingSend(false);
        reload(3000);
      })
      .catch((err) => {
        setLoadingSend(false);
        toast.error((err.response && err.response.data.errmsg) || 'Erro ao executar o envio');
      });
  }
  // --------------------------------------------- //

  return (
    <div className={modalHSMStyle.modalScreen}>
      {!excelData.filemailing ? (
        <div className="d-flex flex-column align-items-center">
          {' '}
          Suba seus contatos através de um arquivo Excel
          <div className="mt-3" onClick={(e) => fileClick(e)}>
            <ActionBtn color="green" icon={<AttachFile style={{ color: 'white' }} />} text="Escolher Lista" />
          </div>
          <input
            id="file-input"
            type="file"
            accept=".application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls,.xlsx"
            onChange={(e) => handleUploadXLSX(e.target)}
            hidden
          />
        </div>
      ) : null}

      {excelData.filemailing ? (
        <div className={modalHSMStyle.entireModal}>
          <div className="onScreenHSM">
            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Nome da Lista</label>
              </div>
              <input
                required
                className={`input-bootstrap ${modalHSMStyle.maxInput}`}
                onChange={(e) => setNameMailing(e.target.value)}
                placeholder="Ex: Lista de vendas"
              />
            </div>

            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Número do remetente</label>
              </div>
              <select
                disabled={oneSenderEntry.length === 1 ? true : false}
                className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                id="register__profile"
                required
                onChange={(e) => {
                  setSender(JSON.parse(e.target.value));
                  getMessages(e.target.value, e.target);
                }}
              >
                {oneSenderEntry.length === 1 ? (
                  <option value={oneSenderEntry[0].numero}>{oneSenderEntry[0].numero}</option>
                ) : (
                  <option defaultValue="" hidden>
                    Selecione o número de envio
                  </option>
                )}
                {config.fakeConfig.defaultBroker !== 'disable'
                  ? oneSenderEntry.map((value, idx) => (
                      <option
                        value={JSON.stringify({
                          numberSelected: value,
                          broker: config.fakeConfig.defaultBroker,
                        })}
                        key={idx}
                      >
                        {value.numero}
                      </option>
                    ))
                  : config.fakeConfig.enabled.map((broker) =>
                      config.fakeConfig.brokers[broker].senders.map((value, idx) => (
                        <option
                          value={JSON.stringify({
                            numberSelected: value,
                            broker: broker,
                          })}
                          key={idx}
                        >
                          {value.numero}
                        </option>
                      ))
                    )}
              </select>
            </div>

            {/* {sender?.broker ?  */}
            <div className="mb-3">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Mensagem</label>
              </div>
              <select
                className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                onChange={(e) => {
                  handleMessage(JSON.parse(e.target.value));
                }}
                // disabled={!sender?.numberSelected?.numero}
              >
                <option defaultValue="" hidden>
                  {' '}
                  Selecione...{' '}
                </option>
                {messages.map((item, idx) => (
                  <option key={idx} value={JSON.stringify(item)}>
                    {item.codemessage}
                  </option>
                ))}
              </select>
            </div>
            {/* : null} */}

            {/* {sender?.broker ?  */}
            <div className="onScreenTXT">
              <div className="containerLabel mb-1">
                <label className="defaultLabel">Prévia da Mensagem</label>
              </div>
              <TextareaAutosize
                // minRows={3}
                // maxRows={5}
                className="text-area-bootstrap w-75"
                placeholder="Escolha uma mensagem para carregar uma prévia"
                disabled
                value={selectedMessage !== null ? selectedMessage.body : ''}
              />
            </div>
            {/* : null} */}

            <div className="paramsZone">
              {/* {insertingParams()}  */}
              {selectedMessage && excelData && excelData.columns && selectedMessage.parametros ? (
                !loading ? (
                  Object.keys(selectedMessage.parametros).map((item, idx) => {
                    return (
                      <div className={modalHSMStyle.paramSendList}>
                        <div className="containerLabel">
                          <label className="defaultLabel">
                            Parâmetro {idx + 1} - {selectedMessage.parametros[item]}
                          </label>
                        </div>

                        <select
                          className={`select-bootstrap ${modalHSMStyle.maxSelectInput}`}
                          required
                          value={dataParam[idx + 1]}
                          onChange={(e) => {
                            handleParam(idx + 1, e.target.value);
                          }}
                        >
                          <option value="">Selecione...</option>
                          {Object.keys(excelData.columns).map((item, idx) => {
                            return (
                              <option key={idx} value={excelData.columns[item]}>
                                {excelData.columns[item]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  })
                ) : (
                  <ParamLoading />
                )
              ) : null}
            </div>
          </div>

          <div className="mt-3">
            <div className="containerLabel">
              <label className="defaultLabel">Prévia dos Contatos</label>
            </div>
            <div>
              <TablePreview mailing={excelData} page={page} setPage={setPage} />
            </div>
          </div>

          {/* <div style={{ marginBottom: "1vh" }}>
            <Grid container justifyContent="space-evenly">
              <Grid>
                {loadingSend ? (
                  <CircularProgress />
                ) : nameMailing && selectedMessage && paramsDefined ? (
                  <button className="saveBtn" onClick={() => handleSubmit()}>
                    Salvar
                  </button>
                ) : (
                  <button className="blockedSaveBtn" disabled>
                    Salvar
                  </button>
                )}
              </Grid>
            </Grid>
          </div> */}

          <div className="mt-4 pb-4 d-flex justify-content-center">
            <button
              onClick={() => handleSubmit()}
              disabled={!nameMailing || !selectedMessage || !paramsDefined}
              type="button"
              className="btn btn-primary containerBtn-4 me-5 mb-3"
            >
              Salvar
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
