import React, { useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import api from "../../services/api";
import { toast } from "react-toastify";
import { config } from "../../services/utils";
import ComebackTable from "./historyTable";
import comebackStyle from "./comeback.module.css";

import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";

export default function ComebackHistory() {
  const [origin, setOrigin] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [retornoAPI, setRetornoAPI] = useState({});
  const [loading, setLoading] = useState(true);

  function searchingReturns() {
    setLoading(false);

    api
      .get(
        `api/espresso/hibernatedPeople?fromDate=${fromDate}&toDate=${toDate}${
          origin.length > 0 ? `&origin=${origin}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setLoading(true);
        setRetornoAPI(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
        toast.error("Erro #003: Falha na pesquisa");
      });
  }

  const clearData = () => {
    setRetornoAPI([]);
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className="container me-5">
      <div className="d-flex justify-content-between">
        <p className="titlePage">Histórico de Retornos</p>
        <div className="d-flex align-items-center">
          <div
            className={` d-flex align-items-center me-3 ${comebackStyle.containerTimer}`}
          >
            <label className="defaultLabel me-1">De:</label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={fromDate}
              id="searchdatefrom"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>

          <div
            className={`d-flex align-items-center me-3 ${comebackStyle.containerTimer}`}
          >
            <label className="defaultLabel me-1">Até:</label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={toDate}
              id="searchdateto"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>

          <div
            className={`d-flex align-items-center ${comebackStyle.containerSelect}`}
          >
            <label className="defaultLabel me-1">Origem:</label>
            <select
              className="select-bootstrap"
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            >
              <option value=""> Todos </option>
              {config.summarize.map((value) => (
                <option value={value.origin}>{value.visualName}</option>
              ))}
            </select>
          </div>

          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              searchingReturns();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <ComebackTable retornoAPI={retornoAPI} loading={loading} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
