import { config } from "../utils.js";

export const flowMenus = [
  {
    key: "reportAnalitics",
    label: "Analítico",
    route: "/app/summarize/HistoryA",
  },
  {
    key: "botWorkFlowDashboard",
    label: "Fluxo de Bot",
    route: "/app/summarize/milestone",
  },
];

export const hasFlowItems = flowMenus.some(
  (item) => config.application.menus?.[item.key]
);
