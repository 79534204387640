import React from "react";
import summarizeStyle from "./summarize.module.css";
import { StyledEngineProvider } from "@mui/material/styles";
import MailingStatusTable from "../../components/tables/MailingStatusTable";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import ActionBtn from "../../components/UI/actionbtn";
import { toast } from "react-toastify";
import api from "../../services/api";
import { checkDate, config, maxDays } from "../../services/utils";

const defaultCDRSearch = {
  from_dateTime: "",
  to_dateTime: "",
  list: "",
};
const dateConversion = (dateToConvert) => {
  return new Date(new Date(dateToConvert).getTime()).toISOString();
};

export default function MailingStatusReport() {
  const [CDRSearch, setCDRSearch] = React.useState({ ...defaultCDRSearch });
  const [retornoAPI, setRetornoAPI] = React.useState([]);
  const [call, setCall] = React.useState(true);
  const [disable, setDisabled] = React.useState(true);
  const [dataSelect, setDataSelect] = React.useState([]);

  const handleChange = (type, value) => {
    let _CDRSearch = { ...CDRSearch };
    _CDRSearch[type] = value;
    setCDRSearch({ ..._CDRSearch });
    if (_CDRSearch.to_dateTime != "" && _CDRSearch.from_dateTime != "") {
      loadList(
        dateConversion(_CDRSearch.from_dateTime),
        dateConversion(_CDRSearch.to_dateTime)
      );
    }
  };

  const loadList = async (from, to) => {
    await api
      .get(
        `api/database/report/ra_mailings?date_start=${from}&date_end=${to}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setDataSelect(res.data);
        setDisabled(false);
      });
  };

  const loadTables = async () => {
    setCall(false);
    if (!CDRSearch.from_dateTime || !CDRSearch.to_dateTime) {
      toast.warn("Favor inserir datas de pesquisa");
      setCall(true);
    }
    if (!!CDRSearch.from_dateTime && !!CDRSearch.to_dateTime) {
      if (checkDate(CDRSearch.to_dateTime)) {
        if (
          maxDays(CDRSearch.from_dateTime, CDRSearch.to_dateTime, 11) &&
          CDRSearch.list !== ""
        ) {
          await api
            .get(
              `api/database/report/ra_status_mailing_a?mailing=${CDRSearch.list}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              if (res.data.length === 0) {
                toast.error("Não há relatórios para esta data");
              } else {
                toast.success("Busca realizada com sucesso");
              }
              setCall(true);
              setRetornoAPI(res.data);
              return;
            })
            .catch((err) => {
              toast.error("Erro #003: Falha na pesquisa");
              console.log("Ocorreu um erro: ", err);
              setCall(true);
            });
        } else {
          setCall(true);
          toast.warn(
            "As datas excedem o limite de 10 dias, ou nenhuma lista foi selecionada"
          );
        }
      } else {
        setCall(true);
        toast.warn("Favor inserir data maior ou igual a 01/10/2022");
      }
    }
  };

  const cleanSearch = () => {
    setCDRSearch({ ...defaultCDRSearch });
    setRetornoAPI([]);
    toast.success("Os Campos foram limpos");
  };

  return (
    <div>
      <div className={summarizeStyle.summarize}>
        <div className="d-flex align-items-center justify-content-between pe-4 mt-2 mb-1">
          <div className="titlePage">Status do Mailing</div>
          <div className="d-flex align-items-center">
            <div
              className={`${summarizeStyle.containerTimerHeader} d-flex align-items-center`}
            >
              <label className="defaultLabel me-1">De: </label>
              <input
                className="input-bootstrap me-3"
                type="dateTime-Local"
                value={CDRSearch.from_dateTime}
                id="searchdatefrom"
                onChange={(e) => handleChange("from_dateTime", e.target.value)}
              />
            </div>

            <div
              className={`${summarizeStyle.containerTimerHeader} d-flex align-items-center`}
            >
              <label className="defaultLabel me-1">Até: </label>
              <input
                className="input-bootstrap me-3"
                type="dateTime-Local"
                value={CDRSearch.to_dateTime}
                id="searchdateto"
                onChange={(e) => handleChange("to_dateTime", e.target.value)}
              />
            </div>

            <div
              className={`${summarizeStyle.containerSelectHeader} d-flex align-items-center`}
            >
              <label className="defaultLabel me-1">Origin: </label>
              <select
                disabled={disable}
                className="select-bootstrap me-3"
                value={CDRSearch.list}
                onChange={(e) => handleChange("list", e.target.value)}
              >
                <option value="">Selecionar</option>
                {dataSelect.map((value, index) => (
                  <option
                    key={`index-${index}-${value.codemailing}`}
                    value={value.codemailing}
                  >
                    {value.namemailing}
                  </option>
                ))}
              </select>
            </div>

            <div
              onClick={() => {
                cleanSearch();
              }}
            >
              <ActionBtn color="yellow" icon={<SearchOffIcon />} />
            </div>

            <div
              onClick={() => {
                loadTables();
              }}
            >
              <ActionBtn color="green" icon={<SearchIcon />} />
            </div>
          </div>
        </div>
        <div className={summarizeStyle.sumBottomBox}>
          <StyledEngineProvider injectFirst>
            <MailingStatusTable data={retornoAPI} />
          </StyledEngineProvider>
        </div>
      </div>
    </div>
  );
}
