import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const columns = [
  {
    field: "queueName",
    headerName: "Nome Da Fila",
    flex: 2,
    renderCell: (params) => {
      if (params.value === null) {
        return "System.Empty";
      }
      return params.value;
    },
  },
  { field: "Origem", headerName: "Origem", flex: 2 },
  { field: "Phone", headerName: "Número", flex: 1 },
  {
    field: "startTime",
    headerName: "Inicio da Hibernação",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root" :{
      marginLeft: "-2vh",
    }
  },
})(DataGrid);

export default function HibernationStatusTable({ hibernationStatus }) {
  var rows = [];

  for (let i = 0; i < hibernationStatus.length; i++) {
    hibernationStatus[i].startTime = new Date(hibernationStatus[i].startTime)
    rows.push({
      id: i + 1,
      alteration: hibernationStatus[i]._id,
      ...hibernationStatus[i],
    });
  }

  return (
    <div style={{ height: "110%", width: "100%" }}>
      <StyledDataGrid
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
