import { config } from "../utils.js";

export const hibernationMenus = [
  {
    key: "hibernationDashboard",
    label: "Dashboard",
    route: "/app/dashboard/hibernation",
  },
  {
    key: "hibernationReturn",
    label: "Vizualização",
    route: "/app/dashboard/comeback",
  },
  {
    key: "hibernationReturn",
    label: "Histórico",
    route: "/app/comebackHistory",
  },
  {
    key: "reportHibernation",
    label: "Relatório Analítico",
    route: "/app/summarize/hibernation",
  },
  {
    key: "reportHibernation2",
    label: "Relatório Consolidado",
    route: "/app/summarize/hibernation/report2",
  },
];

export const hasHibernationItems = hibernationMenus.some(
  (item) => config.application.menus?.[item.key]
);
