import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import wrapQueueStyle from "./WrapupQueue.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import api from "../../services/api";
const { v4: uuidv4 } = require("uuid");
// import Hierarchy from "./HierarchyList/Hierarchy";
// import { PURECLOUD_URL } from "../../services/utils";
// import ModalCall from "../../components/tables/modalOpen";

export default function WrapupQueue(props) {
  // ----------------- States -------------------------- //
  const [selectedQueue, setSelectedQueue] = useState({});
  const [key, setKey] = useState("");
  const [queue, setQueue] = useState("");
  const [descricao, setDescricao] = useState("");
  const [child, setChild] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  // --------------------------------------------------- //

  useEffect(() => {
    // api
    //   .get(`${PURECLOUD_URL}/users/${localStorage.getItem("userId")}/queues`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
    //     },
    //   })
    //   .then((res) => {
    //     if (res.data.entities.length == 0) {
    //       toast.warn("Usuario não possui filas cadastradas");
    //     } else {
    //       setQueues(res.data.entities);
    //     }
    // })
    // .catch((error) => {
    //   if (
    //     error?.response?.data?.code == "authentication.required" ||
    //     error?.response?.data?.code == "bad.credentials"
    //   ) {
    //     toast.warn("Sessão do genesys expirada");
    //     localStorage.removeItem("pcToken");
    //     reload(3000);
    //   } else {
    //     toast.error("Filas não encontradas");
    //   }
    // });
    setSelectedQueue({ ...props.selectedQueue });
    setKey(props.selectedQueue.key);
    setQueue(props.selectedQueue.queue);
    setDescricao(props.selectedQueue.descricao);
    setChild([...props.selectedQueue.child]);
  }, [props.selectedQueue]);

  // ----------------- Functions ------------------------ //
  function reload() {
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  function submitInformation() {
    let url;
    let dados = selectedQueue;
    if (dados._id) url = `api/espresso/tabulacao/edit/${dados._id}`;
    else {
      url = "api/espresso/tabulacao/create";
      dados = { ...dados, key: uuidv4() };
    }
    api
      .post(
        url,
        { ...dados, queue, descricao },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Cadastrado, atualizando lista");
        reload();
      })
      .catch((err) => toast.error("Erro #005: Erro Fatal! Acionar o suporte"));
  }
  // --------------------------------------------------- //

  let showEditor;
  if (selectedTab)
    showEditor = (
      <span className="pad">
        <label className="defaultLabel">Tabulação</label>
        <div className={wrapQueueStyle.formTabs}>
          <form>
            <div className="codigo mb-4 mt-1">
              <label className="defaultLabel">Código</label>
              <input
                className="input-bootstrap"
                type="text"
                required
                onChange={(e) =>
                  setSelectedTab({ ...selectedTab, codigo: e.target.value })
                }
                value={selectedTab.codigo}
              />
            </div>
            <div className="descricao">
              <label className="defaultLabel">Descrição</label>
              <input
                className="input-bootstrap"
                type="text"
                required
                onChange={(e) =>
                  setSelectedTab({ ...selectedTab, descricao: e.target.value })
                }
                value={selectedTab.descricao}
              />
            </div>
          </form>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <button
              className="btn btn-primary"
              onClick={(e) => {
                updateTab(selectedTab);
                e.stopPropagation();
              }}
            >
              Confirmar
            </button>
          </div>
        </div>
      </span>
    );

  return (
    <div className={wrapQueueStyle.containerWrap}>
      <form onSubmit={(e) => submitInformation(e)}>
        <div className={`mb-3`}>
          <label className="defaultLabel">Fila</label>
          <input
            className="input-bootstrap mt-1"
            type="text"
            required
            onChange={(e) => setQueue(e.target.value)}
            value={queue}
            placeholder="Ex: Fila ABC"
          />
        </div>
        <div className={`mb-4`}>
          <label className="defaultLabel">Descrição</label>
          <input
            className="input-bootstrap mt-1"
            type="text"
            required
            onChange={(e) => setDescricao(e.target.value)}
            value={descricao}
            placeholder="Ex: Encerramento da fila A"
          />
        </div>
      </form>
      <div className="d-flex w-100">
        <div>
          <label className="defaultLabel">Tabulações</label>
          <div
            className={wrapQueueStyle.tabulacoes}
            onClick={(e) => {
              setSelectedTab(null);
            }}
          >
            <div className="d-flex align-items-center">
              <span className={`${wrapQueueStyle.slash} me-1`}>\</span>
              <AddIcon
                style={{ color: "white" }}
                className={wrapQueueStyle.addIconStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  addNew(key);
                }}
              />
            </div>
            {renderTab(selectedQueue)}
          </div>
        </div>
        <div className={wrapQueueStyle.containerEditor}>{showEditor}</div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button
          className="btn btn-primary px-5"
          onClick={(e) => {
            submitInformation();
          }}
        >
          Confirmar
        </button>
      </div>
    </div>
  );

  function renderTab(parent, idx = 0) {
    const child = parent.child ?? [];
    return (
      <ul>
        {child.map(function (tab) {
          // console.log(selectedTab, tab, selectedTab == tab);
          let btn;
          if (idx < 4) {
            btn = (
              <AddIcon
                style={{ color: "white" }}
                className={wrapQueueStyle.addIconStyle2}
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  addNew(tab.key);
                }}
              />
            );
          }
          let childs;
          if (tab.child?.length > 0) childs = renderTab(tab, idx + 1);
          return (
            <li key={tab.key}>
              <div className="d-flex align-items-center">
                <span
                  className={
                    selectedTab?.key === tab.key
                      ? `${wrapQueueStyle.active}`
                      : `${wrapQueueStyle.select}`
                  }
                  onClick={(e) => {
                    setSelectedTab(tab);
                    e.stopPropagation();
                  }}
                >
                  {tab.codigo} - {tab.descricao}
                </span>
                {btn}
                <DeleteIcon
                  style={{ color: "white" }}
                  className={wrapQueueStyle.delIconStyle}
                  fontSize="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTab(tab.key);
                  }}
                />
              </div>
              {childs}
            </li>
          );
        })}
      </ul>
    );
  }

  function addNew(parentKey) {
    function addIntoChild(pKey, self, newTab) {
      if (!self.child) self.child = [];
      if (self.key == pKey) {
        self.child.push(newTab);
        return true;
      } else {
        for (const ch of self.child) {
          if (addIntoChild(pKey, ch, newTab)) return true;
        }
      }
      return false;
    }

    let nTab = {
      key: uuidv4(),
      descricao: "descrição",
      codigo: "código",
    };
    const q = selectedQueue;
    addIntoChild(parentKey, q, nTab);
    setSelectedQueue({ ...q });
    setSelectedTab(nTab);
    setChild(q.child);
  }

  function updateTab(tab) {
    function updateTabChild(self, nTab) {
      if (!self.child) self.child = [];
      for (const ch in self.child) {
        if (self.child[ch].key == nTab.key) {
          self.child[ch] = nTab;
          return true;
        }
        if (updateTabChild(self.child[ch], nTab)) return true;
      }
      return false;
    }
    const q = selectedQueue;
    updateTabChild(q, tab);
    setSelectedQueue({ ...q });
    setSelectedTab(null);
    setChild(q.child);
  }

  function deleteTab(key) {
    function deleteTabChild(self, tabKey) {
      if (!self.child) self.child = [];
      for (const ch in self.child) {
        if (self.child[ch].key == tabKey) {
          self.child.splice(ch, 1);
          return true;
        }
        if (deleteTabChild(self.child[ch], tabKey)) return true;
      }
      return false;
    }
    if (
      window.confirm(
        "Deseja excluir essa tabulação e todas as suas tabulações filhas?"
      )
    ) {
      const q = selectedQueue;
      deleteTabChild(q, key);
      setSelectedQueue({ ...q });
      setSelectedTab(null);
      setChild(q.child);
    }
  }
}
