import cdrStyles from "./cdrPage.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import CdrItrTable from "../../components/tables/cdrIteractions";
import api from "../../services/api";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { checkDate, config, maxDays } from "../../services/utils";

const { checkDates } = config.application.features;

export default function CdrLog() {
  const defaultCDRSearch = {
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    from_dateTime: "",
    to_dateTime: "",
    origin: "",
  };

  const [CDRSearch, setCDRSearch] = useState({ ...defaultCDRSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [call, setCall] = useState(true);

  const handleChange = (type, value) => {
    let _CDRSearch = { ...CDRSearch };
    _CDRSearch[type] = value;
    setCDRSearch({ ..._CDRSearch });
  };

  const loadTables = async () => {
    setCall(false);
    let cdrReq = {};

    if (
      CDRSearch.from_date &&
      CDRSearch.to_date &&
      !CDRSearch?.from_time &&
      !CDRSearch?.to_time
    ) {
      CDRSearch.from_dateTime = "" + CDRSearch.from_date + ":00.001Z";
      CDRSearch.to_dateTime = "" + CDRSearch.to_date + ":00.002Z";
      cdrReq = {
        from_dateTime: CDRSearch.from_dateTime,
        to_dateTime: CDRSearch.to_dateTime,
      };
    }

    if (!checkDates || checkDate(CDRSearch.from_date)) {
      if (maxDays(CDRSearch.from_date, CDRSearch.to_date, 31)) {
        // brasilseg utiliza este padrao
        // await api
        //   .get(`api/database/report/cdr_interacoes?date_from=${CDRSearch.from_date}&date_to=${CDRSearch.to_date}&origin=${CDRSearch.origin}`,{
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("token")}`,
        //     },
        //   })
        //   .then((res) => {
        //     if (res.data.length === 0) {
        //       toast.error("Não há relatórios para esta data");
        //     } else {
        //       toast.success("Busca realizada com sucesso");
        //     }
        //     setCall(true)
        //     setRetornoAPI(res.data);
        //     return;
        //   })
        //   .catch((err) => {
        //     setCall(true)
        //     toast.error("Erro #003: Falha na pesquisa");
        //   });

        // let cdrReq = {
        //   from_dateTime: CDRSearch.from_dateTime,
        //   to_dateTime: CDRSearch.to_dateTime,
        // };
        await api
          .get(
            `api/database/report/cdr_interacoes?date_from=${
              CDRSearch.from_date
            }&date_to=${CDRSearch.to_date}&origin_ura=${
              CDRSearch.origin.length > 0 ? CDRSearch.origin : ""
            }`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.length === 0) {
              toast.error("Não há relatórios para esta data");
            } else {
              toast.success("Busca realizada com sucesso");
            }
            setCall(true);
            setRetornoAPI(res.data);
            return;
          })
          .catch((err) => {
            setCall(true);
            toast.error("Erro #003: Falha na pesquisa");
          });
      } else {
        setCall(true);
        toast.warn("As datas excedem o limite de 31 dias");
      }
    } else {
      setCall(true);
      toast.warn("Favor inserir data maior ou igual a 01/10/2022");
    }
  };

  const cleanSearch = () => {
    setCDRSearch({ ...defaultCDRSearch });
    setRetornoAPI({ ...[] });
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className={cdrStyles.cdrLog}>
      <div className="d-flex align-items-center justify-content-between pe-4">
        <div className="titlePage">Relatório de Iterações</div>
        <div className="d-flex">
          <div
            className={`${cdrStyles.containerCdrPageHeader} d-flex align-items-center me-2`}
          >
            <label className="defaultLabel me-2">Origin: </label>
            <select
              className="select-bootstrap"
              value={CDRSearch.origin}
              onChange={(e) => handleChange("origin", e.target.value)}
            >
              <option value="">Todos</option>
              {config.summarize.map((value) => (
                <option value={value.origin}>{value.visualName}</option>
              ))}
            </select>
          </div>

          <div
            className={`${cdrStyles.containerInput} d-flex align-items-center me-2`}
          >
            <label className="defaultLabel me-2">De: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={CDRSearch.from_date}
              id="searchdatefrom"
              onChange={(e) => handleChange("from_date", e.target.value)}
            />
          </div>
          <div
            className={`${cdrStyles.containerInput} d-flex align-items-center me-2`}
          >
            <label className="defaultLabel me-2">Até: </label>
            <input
              type="dateTime-Local"
              value={CDRSearch.to_date}
              id="searchdateto"
              onChange={(e) => handleChange("to_date", e.target.value)}
              className="input-bootstrap"
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={cdrStyles.cdrBottomBox}>
        <StyledEngineProvider injectFirst>
          <CdrItrTable
            loading={call}
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: "black", color: "white" }}
          ></CdrItrTable>
        </StyledEngineProvider>
      </div>
    </div>
  );
}
