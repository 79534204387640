import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../../services/api";
import { editCell, config } from "../../services/utils";
import "./period.css"

export default function RegisterPeriod({ blackList }) {
  // -------------------------- States --------------------------- //
  const [period, setPeriod] = useState({ body: {} });
  // const {type,upload, broker} = config.application.modules.templates;
  // ------------------------------------------------------------ //

  // useEffect(() => {
  //   async function callAPI() {
  //     if (codemessage) {
  //       const editValues = await editCell(codemessage);
  //       let qtd = 0;
  //       let parametros = [];
  //       for (let key in editValues.parametros) {
  //         parametros.push(editValues.parametros[key]);
  //       }
  //       // setColor(editValues.broker)
  //       SetParamList(parametros);
  //       // setNameSpace(editValues.namespace)
  //       // setElementName(editValues.elementname)
  //       setOrdem(parametros.length);
  //       setMensagem({ body: editValues });
  //     }
  //   }
  //   callAPI();
  // }, []);

  // ------------------------ Functions ------------------------- //

  function reload() {
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  const handleChange = (type, value) => {
    let _period = { ...period };
    _period.body[type] = value;
    setPeriod({ ..._period });
  };

  function timeCalculation(selected) {
    const selectOptions = [5, 10, 15, 30];
    let selector = selected;

    if (selector) {
      for (let i = 60; i < 721; i = i + 30) {
        selectOptions.push(i);
      }
    }

    if (selector === false) {
      for (let i = 60; i < 1441; i = i + 60) {
        selectOptions.push(i);
      }
    }

    return selectOptions.map((time) => (
      <option className="centerDrop" value={time}>
        {toHour(time)}
      </option>
    ));
  }

  function toHour(time) {
    if (time >= 60 && time % 60 != 0) {
      return ("0" + (time - 30) / 60).slice(-2) + ":30";
    }
    if (time >= 60 && time % 60 == 0) {
      return ("0" + time / 60).slice(-2) + ":00";
    }
    return "00:" + ("0" + time).slice(-2);
  }

  const handleSubmit = async () => {
    toast.success("Número Cadastrado com sucesso")
  };
  // ------------------------------------------------------------------ //

  return (
    <div className="blacklisting">
      {/* <form onSubmit={() => handleSubmit()}> */}
        <div className="textScope">
          <label>Ura: </label>
          <select
            className="selectTimer"
            required
            onChange={(e) => handleChange("ura", e.target.value)}
            value={period.body.ura}
          >
              <option disabled selected value=""> Selecione uma ura </option>
              {config.fakeConfig.brokers[config.fakeConfig.defaultBroker].senders.map((value, idx) => (
              <option key={idx} value={value.origin}>{value.origin}</option>
            ))}
          </select>
        </div>

        <div className="textScope">
          <label className="labeling">Periodo: </label>
          <select
            className="selectTimer"
            required
            onChange={(e) =>
              handleChange("period", e.target.value)
            }
            value={period.body.period}
          >
            <option value={null} hidden>
              Selecione uma opção
            </option>
            {timeCalculation(true)}
          </select>
        </div>

        <div className="textScope">
          <label>Mensagem: </label>
          <textarea
            required
            onChange={(e) => handleChange("numero", e.target.value)}
            value={period.body.numero}
          />
        </div>

        <button className="saveBtn" onClick={() => handleSubmit()}>
          Salvar
        </button>
      {/* </form> */}
    </div>
  );
}
