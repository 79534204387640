import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import sidebarStyles from "./newSidebar.module.css";
import Tooltip from "@mui/material/Tooltip";
import {
  hasDashboardItems,
  dashMenus,
} from "../../services/sideBarVerification/dashMenus";
import {
  hasToolsMenu,
  toolsMenus,
} from "../../services/sideBarVerification/ToolsMenu";
import {
  hasListItems,
  listMenus,
} from "../../services/sideBarVerification/listMenus";
import {
  hasRegisterItems,
  registerMenus,
} from "../../services/sideBarVerification/registerMenus";
import {
  hasHibernationItems,
  hibernationMenus,
} from "../../services/sideBarVerification/hibernationMenus";
import {
  hasSchedulesItems,
  schedulesMenus,
} from "../../services/sideBarVerification/schedulesMenus";
import {
  hasFlowItems,
  flowMenus,
} from "../../services/sideBarVerification/flowMenus";
import {
  hasReportsItems,
  hasCDRItems,
  hasReclameAquiItems,
  hasHSMSMSItems,
  hasSentimentoItems,
  sentimentoMenus,
  reclameAquiMenus,
  HSMSMSMenus,
  CDRMenus,
} from "../../services/sideBarVerification/reportsMenus";
import { config } from "../../services/utils";

/* --------------- menu imgs -------------------  */
import DashboardIcon from "@mui/icons-material/Dashboard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HistoryIcon from "@mui/icons-material/History";
import CreateIcon from "@mui/icons-material/Create";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import HandymanIcon from "@mui/icons-material/Handyman";
import TodayIcon from "@mui/icons-material/Today";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import logo from "../../images/teste.png";
import logoV from "../../images/whiteLogo.png";

/* ---------------------------------------------- */

export default function NewSidebar() {
  const [collapsed, setCollapsed] = React.useState(true);
  const [tooltip, setTooltip] = React.useState(null);
  const containerRef = React.useRef(null);
  const preProfile1 = localStorage.getItem("userProfile") === "Supervisor";
  const preProfile2 = localStorage.getItem("userProfile") === "Administrador";
  const preProfile3 = localStorage.getItem("userProfile") === "Qualidade";
  const userProfile =
    preProfile1 || preProfile2 || config.application.features.byPass;

  const handleDocumentClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setCollapsed(true);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  function controlIcon() {
    return collapsed
      ? "w-100 d-flex justify-content-center pt-3 pb-3"
      : "w-100 d-flex justify-content-end pt-3 pb-3";
  }

  function clickHandler() {
    setCollapsed(true);
  }

  const CustomTooltip = ({ text, position }) => {
    const style = {
      position: "absolute",
      top: position.y,
      left: 84,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      padding: "6px",
      borderRadius: "4px",
      fontSize: "12px",
      minWidth: "80px",
      textAlign: "center",
    };

    return <div style={style}>{text}</div>;
  };

  const handleMouseEnter = (e, title) => {
    if (collapsed) {
      const tooltipText = title;
      setTooltip({
        text: tooltipText,
        position: { x: e.clientX, y: e.clientY },
      });
    }
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };
  // linear-gradient( to bottom,#6fa7d0,#43b8db,#0052cc,#001f5d)"
  return (
    <div
      className={sidebarStyles.sidebarContainer}
      style={{ display: "flex", height: "100%", minHeight: "400px" }}
    >
      {tooltip && (
        <CustomTooltip text={tooltip.text} position={tooltip.position} />
      )}
      <Sidebar
        ref={containerRef}
        collapsedWidth={"75px"}
        transitionDuration={500}
        collapsed={collapsed}
        rootStyles={{
          maxWidth: "14vw",
          width: "14vw",
          minWidth: "14vw",
          background: "#2193b0",
          color: "#fff",
          fontSize: "14px",
          fontFamily: "Open sans",
          border: "none",
        }}
      >
        {collapsed ? (
          <div className="pb-5">
            {" "}
            <Link to={"/"}>
              <img
                width="55px"
                className={sidebarStyles.img}
                src={logo}
                alt="Like"
              />
            </Link>
          </div>
        ) : null}
        <div className={controlIcon()}>
          <button
            className={sidebarStyles.buttonMenu}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuIcon /> : <CloseIcon />}
          </button>
        </div>
        {!collapsed ? (
          <div className={sidebarStyles.containerLogoOpen}>
            <Link to={"/"}>
              <img className={sidebarStyles.imgV} src={logoV} alt="Like" />
            </Link>
          </div>
        ) : null}
        <Menu closeOnClick>
          {hasDashboardItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Dashboard"
              icon={
                <DashboardIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Dashboard")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {dashMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {config.application.menus.history && (userProfile || preProfile3) ? (
            <MenuItem
              icon={
                <HistoryIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Histórico")}
                  onMouseLeave={handleMouseLeave}
                />
              }
              component={<Link to="/app/history" />}
              onClick={clickHandler}
            >
              Histórico
            </MenuItem>
          ) : null}
          {hasToolsMenu && (userProfile || preProfile3) ? (
            <SubMenu
              label="Ferramentas"
              icon={
                <HandymanIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Ferramentas")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {toolsMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}

          {hasListItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Listas"
              icon={
                <ListAltIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Listas")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {listMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {hasRegisterItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Cadastros"
              icon={
                <CreateIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Cadastros")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {registerMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {hasHibernationItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Hibernação"
              icon={
                <BedtimeIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Hibernação")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {hibernationMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {hasSchedulesItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Schudele"
              icon={
                <TodayIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Schedule")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {schedulesMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {hasFlowItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Flow"
              icon={
                <AccountTreeIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Flow")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {flowMenus.map(
                (item) =>
                  config.application.menus?.[item.key] && (
                    <MenuItem
                      onClick={clickHandler}
                      key={item.key}
                      component={<Link to={item.route} />}
                    >
                      {item.label}
                    </MenuItem>
                  )
              )}
            </SubMenu>
          ) : null}
          {hasReportsItems && (userProfile || preProfile3) ? (
            <SubMenu
              label="Relatórios"
              icon={
                <SummarizeIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Relatórios")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              {config.application.menus.reportBolsao &&
              (userProfile || preProfile3) ? (
                <MenuItem
                  onClick={clickHandler}
                  component={<Link to="/app/summarize/bolsao" />}
                >
                  Bolsão
                </MenuItem>
              ) : null}
              {hasCDRItems ? (
                <SubMenu label="CDR">
                  {CDRMenus.map(
                    (item) =>
                      config.application.menus?.[item.key] && (
                        <MenuItem
                          onClick={clickHandler}
                          key={item.key}
                          component={<Link to={item.route} />}
                        >
                          {item.label}
                        </MenuItem>
                      )
                  )}
                </SubMenu>
              ) : null}
              {hasHSMSMSItems ? (
                <SubMenu label="HSM/SMS">
                  {HSMSMSMenus.map(
                    (item) =>
                      config.application.menus?.[item.key] && (
                        <MenuItem
                          onClick={clickHandler}
                          key={item.key}
                          component={<Link to={item.route} />}
                        >
                          {item.label}
                        </MenuItem>
                      )
                  )}
                </SubMenu>
              ) : null}
              {hasSentimentoItems ? (
                <SubMenu label="Sentimento">
                  {sentimentoMenus.map(
                    (item) =>
                      config.application.menus?.[item.key] && (
                        <MenuItem
                          onClick={clickHandler}
                          key={item.key}
                          component={<Link to={item.route} />}
                        >
                          {item.label}
                        </MenuItem>
                      )
                  )}
                </SubMenu>
              ) : null}
              {hasReclameAquiItems ? (
                <SubMenu label="Reclame Aqui">
                  {reclameAquiMenus.map(
                    (item) =>
                      config.application.menus?.[item.key] && (
                        <MenuItem
                          onClick={clickHandler}
                          key={item.key}
                          component={<Link to={item.route} />}
                        >
                          {item.label}
                        </MenuItem>
                      )
                  )}
                </SubMenu>
              ) : null}
            </SubMenu>
          ) : null}
          {config.application.menus.monitoring &&
          (userProfile || preProfile3) ? (
            <MenuItem
              onClick={clickHandler}
              component={<Link to="/app/monitoring/" />}
              icon={
                <MonitorHeartIcon
                  onMouseEnter={(e) => handleMouseEnter(e, "Monitores")}
                  onMouseLeave={handleMouseLeave}
                />
              }
            >
              Monitores
            </MenuItem>
          ) : null}
        </Menu>
      </Sidebar>
    </div>
  );
}
