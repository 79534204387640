import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import GenericTable from "../../components/tables/genericTable.js";
import search from "../../images/search.svg";
import clear from "../../images/clear.svg";
import api from "../../services/api";
import { config, maxDays } from "../../services/utils";
import { toast } from "react-toastify";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import CircularIndeterminate from "../../components/UI/loadingComponentThin";
import CircularLoading from "../../components/loadModules/circularLoading";

export default function Summarize() {
  const _filter = {
    date: { from: "", to: "" },
    origin: "",
  };
  const [filter, setFilter] = useState(_filter);
  const [data, setData] = useState([]);
  const [call, setCall] = useState(true);

  // useEffect(async () => {
  //   const res = await api.post(
  //     'api/inbound/report/getBolsaoHistory',
  //     defaultHSMSearch,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('token')}`,
  //       },
  //     }
  //   );
  //   loadTables();
  // }, []);

  // const [HSMSearch, setHSMSearch] = useState({ ...defaultHSMSearch });
  // const [retornoAPI, setRetornoAPI] = useState([]);

  // const handleChange = (type, value) => {
  //   let _HSMSearch = { ...HSMSearch };
  //   _HSMSearch[type] = value;
  //   setHSMSearch({ ..._HSMSearch });
  // };

  const queryData = async () => {
    setCall(false);
    const apiFilter = {};
    if (filter.origin !== "") apiFilter.origin = filter.origin;
    if (filter.date.from !== "") apiFilter.date_start = filter.date.from;
    if (filter.date.to !== "") apiFilter.date_end = filter.date.to;
    if (maxDays(filter.date.from, filter.date.to, 10)) {
      await api
        .get("api/database/report/sentimentoc", {
          params: apiFilter,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            toast.error("Não há relatórios para esta data");
          } else {
            toast.success("Busca realizada com sucesso");
          }
          setCall(true);
          setData(res.data);
          return;
        })
        .catch((err) => {
          toast.error("Erro #003: Falha na pesquisa");
          setCall(true);
        });
    } else {
      setCall(true);
      toast.warn("As datas excedem o valor de 10 dias");
    }
  };

  const clearData = () => {
    setFilter(_filter);
    setData([]);
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex align-items-center justify-content-between pe-4">
        <p className={`titlePage ${summarizeStyle.fontMinor}`}>
          Sentimento - Consolidado
        </p>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`d-flex align-items-center ${summarizeStyle.containerSelectHeader} me-3`}
            >
              <label className="defaultLabel me-2">Origem:</label>
              <select
                className="select-bootstrap"
                value={filter.origin}
                onChange={(e) =>
                  setFilter({ ...filter, origin: e.target.value })
                }
              >
                <option defaultValue>Selecione a Origem</option>
                {config.summarize.map((value) => (
                  <option value={value.origin}>{value.visualName}</option>
                ))}
              </select>
            </div>

            <div
              className={`d-flex align-items-center ${summarizeStyle.containerTimerHeader} me-3`}
            >
              <label className="defaultLabel me-2">De:</label>
              <input
                className="input-bootstrap"
                type="datetime-local"
                value={filter.date.from}
                id="searchdatefrom"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    date: { ...filter.date, from: e.target.value },
                  })
                }
              />
            </div>

            <div
              className={`d-flex align-items-center ${summarizeStyle.containerTimerHeader} me-3`}
            >
              <label className="defaultLabel me-2">Até: </label>
              <input
                className="input-bootstrap"
                type="datetime-local"
                value={filter.date.to}
                id="searchdateto"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    date: { ...filter.date, to: e.target.value },
                  })
                }
              />
            </div>
          </div>
          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              queryData();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          {call ? (
            <GenericTable
              data={data}
              columns={[
                {
                  field: "origin",
                  headerName: "Origem",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "interactions",
                  headerName: "Palavras",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "positive",
                  headerName: "Palavras Positivas",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "negative",
                  headerName: "Palavras Negativas",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
                {
                  field: "neutral",
                  headerName: "Palavras Neutras",
                  flex: 1,
                  align: "center",
                  headerAlign: "center",
                },
              ]}
            />
          ) : (
            <div style={{ width: "96%", height: "110%" }}>
              <CircularLoading />
            </div>
          )}
          {/* <HistoryTable
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: 'black', color: 'white' }}
          ></HistoryTable> */}
        </StyledEngineProvider>
      </div>
    </div>
  );
}
