import React from "react";
import ReactApexChart from "react-apexcharts";

function sizeBar(y) {
  if (y.length == 1) {
    return "20%";
  }
  if (y.length < 3) {
    return "30%";
  }
  if (y.length < 5) {
    return "50%";
  }
  if (y.length < 7) {
    return "70%";
  } else {
    return "100%";
  }
}

export default function BarChartMixed({ dataYAXIS, dataXAXIS }) {
  let config = {
    options: {
      plotOptions: {
        bar: {
          barHeight: sizeBar(dataYAXIS),
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "bottom",
          },
        },
      },

      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      colors: ["#007BFF", "#26979E", "#30ABBC", "#456099"],

      stroke: {
        width: 1,
        colors: ["#fff"],
      },

      xaxis: {
        categories: dataXAXIS,
      },

      legend: {
        show: false,
      },

      yaxis: {
        labels: {
          show: false,
        },
      },

      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  function BuilderGraphicHeight(defaultHeight, Bars) {
    // Bars.lenght // 3
  }

  return (
    <ReactApexChart
      options={config.options}
      series={[{ data: dataYAXIS }]}
      type="bar"
      height={`100%`}
    />
  );
}
