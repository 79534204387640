import React, { useEffect, useState } from "react";
import dashHibStyle from "./dash.module.css";

import api from "../../services/api";
import { toast } from "react-toastify";

/* --------------- dash imgs -------------------  */
import TimelineIcon from "@mui/icons-material/Timeline";
import BarChartMixed from "../../components/charts/barChart";
/* ---------------------------------------------- */

export default function DashboardHibernation() {
  const [exec, setExec] = useState(true);
  const [dataXAXIS, setDataXAXIS] = useState([]);
  const [dataYAXIS, setDataYAXIS] = useState([]);
  const [totalHibernado, setHibernado] = useState(0);

  useEffect(() => {
    async function loadData() {
      await api
        .get("api/espresso/dashboards/hibernationRealTime", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          graphicSettings(res.data);
          setHibernado(reductiveSoma(res.data));
          setTimeout(() => {
            setExec(!exec);
          }, 12000);
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
          setTimeout(() => {
            setExec(!exec);
          }, 12000);
        });
    }

    loadData();
  }, [exec]);

  function graphicSettings(apiResponse) {
    let xAxis = [];
    let yAxis = [];

    for (let i = 0; i < apiResponse.length; i++) {
      yAxis.push(apiResponse[i].count ? apiResponse[i].count : "System.Empty");
      xAxis.push(
        apiResponse[i]._id.Fila ? apiResponse[i]._id.Fila : "System.Empty"
      );
    }

    setDataYAXIS(yAxis);
    setDataXAXIS(xAxis);
  }

  function reductiveSoma(somaValue) {
    let total = 0;
    for (let i = 0; i < somaValue.length; i++) {
      total = somaValue.reduce((total, value) => total + value.count, 0);
    }
    return total;
  }

  return (
    <div className={dashHibStyle.dashHibernation}>
      <div className="d-flex align-items-start justify-content-between pe-5">
        <p className="titlePage">Dashboard</p>
        <div className={dashHibStyle.totCard}>
          <div className={dashHibStyle.totCardHeader}>
            <p>Total Hibernado</p>
          </div>
          <div className={dashHibStyle.totCardBody}>
            <h3>{totalHibernado}</h3>
          </div>
        </div>
      </div>
      <div className={dashHibStyle.fullBox}>
        <div className={dashHibStyle.internalBox}>
          <div className={dashHibStyle.graphicTitleSend}>
            <TimelineIcon className="me-2" style={{ color: "white" }} />
            <span>Status de Filas Hibernadas</span>
          </div>
          <div className={dashHibStyle.graphicZone}>
            <BarChartMixed dataXAXIS={dataXAXIS} dataYAXIS={dataYAXIS} />
          </div>
        </div>

        {/* <div className={dashHibStyle.internalFooter}>
          <div className={dashHibStyle.totalRow}>
            <span>Total Hibernado</span>
          </div>
          <div className={dashHibStyle.totalValue}>
            <span> {totalHibernado} </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}
