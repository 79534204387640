import "./App.css";
import Routes from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getConfig } from "./services/newUtils";


function App() {
  // getConfig()
  return (
    <div className="App">
      <Routes />
      <ToastContainer autoClose={3000} theme="colored" />
    </div>
  );
}

export default App;
