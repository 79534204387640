import React from "react";
import Chart from "react-apexcharts";

export default function PieChartSend({ series }) {
  const config = {
    options: {
      chart: {
        type: "donut",
        horizontalAlign: "center",
      },
      dataLabels: { enabled: false },
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: { width: "100%" },
            legend: { show: true },
          },
        },
      ],

      legend: {
        position: "bottom",
        offsetY: 0,
        height: 45,
        fontSize: "10px",
        horizontalAlign: "center",
      },
      colors: ["#e67e22", "#2ecc71", "#3498db", "#c0392b"],
      labels: ["Recebidos", "Visualizadas", "Respostas", "Erros"],
      dataLabels: { enabled: false },
    },
  };

  return series.length > 0 ? (
    <Chart
      options={config.options}
      series={series}
      type="donut"
      width={"450%" + Math.floor(Math.random() * 2) + 1}
    />
  ) : (
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
