import "./verify.css"
import { useState, useEffect } from "react";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function VerifyFields({ URL, participantData }) {
    const [client, setClient] = useState({})
    const [phone, setPhone] = useState(true)
    const [cpf, setCPF] = useState(true)
    const [cep, setCEP] = useState(true)

    useEffect(() => {
        const clientObject = {
            customerName: participantData.customerName ? participantData.customerName : '',
            phone: participantData.phone ? participantData.phone : '',
            email: participantData.email ? participantData.email : '',
            cpf: participantData.cpf ? participantData.cpf : '',
            birthDate: participantData.birthDate ? dateToInput(participantData.birthDate) : '',
            cep: participantData.cep ? participantData.cep : '',
            sku: participantData.sku ? participantData.sku : '',
            purchaseDate: participantData.purchaseDate ? dateToInput(participantData.purchaseDate) : '',
            orderNumber: participantData.orderNumber ? participantData.orderNumber : '',
            invoiceNumber: participantData.invoiceNumber ? participantData.invoiceNumber : '',
            storeName: participantData.storeName ? participantData.storeName : '',
            BR_URLPop: URL,
            attendant: localStorage.getItem('userName'),
        }

        setClient(clientObject)
    }, [])

    const handleChange = (type, value) => {
        let _client = { ...client };
        _client[type] = value;
        setClient({ ..._client });
    };

    // --------------------------- Phone Verification ------------------------------ //

    const phoneVerificationPattern = (value, qtd) => {
        const regex = qtd ? new RegExp('[+]?[0-9]{2}?[0-9]{2}(9{1})?[0-9]{8}') : new RegExp('[\+]?[0-9]$');
        return regex.test(value);
    }

    const phoneVerify = (event) => {
        let value = event.target.value;
        let _client = { ...client };
        _client.phone = value;
        const isTrueVal = !value || !value == "+" || phoneVerificationPattern(value, true)
        setPhone(isTrueVal)
        if (!value || value == "+" || phoneVerificationPattern(value, false)) setClient({ ..._client });
    }

    // -------------------------------------------------------------------------- //

    // --------------------------- CPF Verification ----------------------------- //

    const cpfVerification = (value, qtd) => {
        const regex = qtd ? new RegExp('([0-9]){11}') : new RegExp('([0-9]$)');
        return regex.test(value);
    }

    const cpfVerify = (event) => {
        let value = event.target.value;
        let _client = { ...client };
        _client.cpf = value;
        const isTrueVal = !value || cpfVerification(value, true)
        setCPF(isTrueVal)
        if (!value || cpfVerification(value, false)) setClient({ ..._client });

    }

    // -------------------------------------------------------------------------- //
    // --------------------------- CEP Verification ----------------------------- //

    const cepVerification = (value, qtd) => {
        const regex = qtd ? new RegExp('[0-9]{8}') : new RegExp('[0-9]$')
        return regex.test(value);
    }

    const cepVerify = (event) => {
        let value = event.target.value;
        let _client = { ...client };
        _client.cep = value;
        const isTrueVal = !value || cepVerification(value, true)
        setCEP(isTrueVal)
        if (!value || cepVerification(value, false)) setClient({ ..._client });
    }

    // -------------------------------------------------------------------------- //

    // --------------------------- Date Verification ---------------------------- //

    function dateToLocal(value) {
        if (!value) return value;
        let splitter = value.split('-')
        let finalDate = `${splitter[2]}/${splitter[1]}/${splitter[0]}`
        return finalDate
    }

    function dateToInput(value) {
        if (!value) return value;
        let splitter = value.split('/')
        let inputDate = `${splitter[2]}-${splitter[1]}-${splitter[0]}`
        return inputDate
    }

    // -------------------------------------------------------------------------- //

    async function handleSubmit(event) {
        event.preventDefault()

        let _client = { ...client };
        _client.birthDate = dateToLocal(_client.birthDate);
        _client.purchaseDate = dateToLocal(_client.purchaseDate);

        await api.post('api/inbound/garantiaAssistida', _client, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then(() => {
                toast.success('Garantia Solicitada')
            }).catch((err) => {
                toast.error(`Falha ao solicitar garantia. error:${err}`)
            })
    }

    return (
        <form className="warrantyForm" onSubmit={(e) => { handleSubmit(e) }}>

            <div className="lineBreak">
                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Nome do Cliente</label>
                        </div>
                        <input
                            value={client.customerName}
                            className="basicInput"
                            onChange={(e) => handleChange('customerName', e.target.value)}
                        />
                    </div>
                </div>

                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Telefone</label>
                        </div>
                        <input
                            value={client.phone}
                            onChange={(e) => phoneVerify(e)}
                            placeholder="+5511912345678"
                            maxLength="14"
                            pattern="[\+]?[0-9]{2}?[0-9]{2}(9{1})?[0-9]{8}"
                            className={`${client.phone > 0 ? (phone ? "verified" : "denied") : "basic"}Input`}
                        />
                    </div>
                    {/* {!phone ? (<div className="alert"><ReportProblemIcon style={{ color: "#f1c40f" }} /> Verificar Telefone </div>) : null} */}
                </div>
            </div>

            <div className="lineBreak">
                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>E-mail do cliente</label>
                        </div>
                        <input value={client.email} className="basicInput" type="email" onChange={(e) => handleChange('email', e.target.value)} />
                    </div>
                </div>

                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>CPF</label>
                        </div>
                        <input
                            value={client.cpf}
                            onChange={(e) => cpfVerify(e)}
                            placeholder="00011122233"
                            maxLength="11"
                            pattern="([0-9]){11}"
                            className={`${client.cpf > 0 ? (cpf ? "verified" : "denied") : "basic"}Input`}
                        />
                    </div>
                    {/* {!cpf ? (<div className="alert"><ReportProblemIcon style={{ color: "#f1c40f" }} /> Verificar CPF </div>) : null} */}
                </div>
            </div>

            <div className="lineBreak">
                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Data de nascimento</label>
                        </div>
                        <input value={client.birthDate} className="basicInput" type="date" max="9999-12-31" onChange={(e) => handleChange('birthDate', e.target.value)} />
                    </div>
                </div>

                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>CEP</label>
                        </div>
                        <input
                            value={client.cep}
                            onChange={(e) => cepVerify(e)}
                            placeholder="00112233"
                            maxLength="8"
                            pattern="([0-9]){8}"
                            className={`${client.cep > 0 ? (cep ? "verified" : "denied") : "basic"}Input`}
                        />
                    </div>
                    {/* {!cep ? (<div className="alert"><ReportProblemIcon style={{ color: "#f1c40f" }} /> Verificar CEP </div>) : null} */}
                </div>
            </div>

            <div className="lineBreak">
                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Modelo do produto</label>
                        </div>
                        <input value={client.sku} className="basicInput" onChange={(e) => handleChange('sku', e.target.value)} />
                    </div>
                </div>

                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Data da Compra</label>
                        </div>
                        <input value={client.purchaseDate} className="basicInput" type="date" max="9999-12-31" onChange={(e) => handleChange('purchaseDate', e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="lineBreak">
                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Número do Pedido</label>
                        </div>
                        <input value={client.orderNumber} className="basicInput" onChange={(e) => handleChange('orderNumber', e.target.value)} />
                    </div>
                </div>

                <div className="verifyFields">
                    <div className="verifyField">
                        <div className="verifyFieldsAlign">
                            <label>Nota Fiscal</label>
                        </div>
                        <input value={client.invoiceNumber} className="basicInput" onChange={(e) => handleChange('invoiceNumber', e.target.value)} />
                    </div>
                </div>
            </div>

            <div className="verifyFields">
                <div className="verifyField">
                    <div className="notVerifiedAlign">
                        <label>Nome da Loja</label>
                    </div>
                    <input value={client.storeName} className="notVerified" onChange={(e) => handleChange('storeName', e.target.value)} />
                </div>
            </div>

            {/* {(phone && cpf && cep) ? */}
            <button type="submit" className="saveBtnStyling">Solicitar Garantia</button>
            {/* :
                <button className="blockedBtnStyling">Solicitar Garantia</button> */}
            {/* } */}
        </form>

    )
}