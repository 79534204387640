import styleHSMTime from "../timedHSM.module.css";
import TimedHSMTable from "./widgetTable";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function WidgetTimedHSM() {
  const [timedHSMAPI, setTimedHSMAPI] = useState({});
  const [exec, setExec] = useState(true);
  const [warned, setWarned] = useState(false);

  //OnPage Load
  useEffect(async () => {
    let payload = payloadConstructor();

    const res = await api
      .post("api/espresso/scheduledcontacts/findbydate", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0 && !warned) {
          toast.warn("Não há agendamentos");
          setWarned(true);
        }
        setTimedHSMAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });

    setTimeout(() => {
      setExec(!exec);
    }, 15000);
  }, [exec]);
  // End Page Load//

  function payloadConstructor() {
    let init = new Date(Date.now()).toISOString().slice(0, 10);
    let next = new Date(Date.now() + 86400000).toISOString().slice(0, 10);
    let payload = {
      initDate: init,
      endingDate: next,
      userName: localStorage.getItem("userName"),
    };
    return payload;
  }

  return (
    <div className={styleHSMTime.timed}>
      <div className="historyToolBar">
        <Link className="margin" to={"/app/screenHSM"}>
          <strong className="sendButton">
            <ArrowBackIcon />
          </strong>
        </Link>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <TimedHSMTable timedHSM={timedHSMAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
