import { config } from "../../../services/utils";
import analitcsStyle from "./analitics.module.css";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../../services/api";

export default function AnaliticsHistoryDownload() {
  const _filter = {
    date: { from: "", to: "" },
    origin: "",
    number: "",
    download: false,
  };
  const [filter, setFilter] = useState(_filter);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState();

  function finding(event) {
    event.persist();
    event.preventDefault();
    let timeStamp = new Date(filter.date.from).getTime();
    let toStamp = new Date(filter.date.to).getTime();
    let maxing = timeStamp + 10 * 24 * 60 * 60 * 1000 + 1;

    if (toStamp <= maxing) {
      const apiFilter = {};
      if (filter.origin !== "") apiFilter.origin = filter.origin;
      if (filter.number !== "") apiFilter.phone = filter.number;
      if (filter.date.from !== "") apiFilter.date_start = filter.date.from;
      if (filter.date.to !== "") apiFilter.date_end = filter.date.to;
      if (filter.download === false) apiFilter.download = true;
      setLoading(true);
      generate(apiFilter);
    } else {
      toast.warn("Data excede o tempo de 10 dias");
    }
  }

  async function generate(req) {
    await api
      .get("api/database/report/historya", {
        params: req,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.warn("Sem dados para este período");
        } else {
          setData(res.data);
          return;
        }
      })
      .catch(() => {
        toast.error("Erro #003: Falha na pesquisa");
      });
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    setFlag(true);
  }

  function clearDownload() {
    setData();
    setFlag(false);
    setLoading(false);
  }

  return (
    <div className={analitcsStyle.analiticsField}>
      <form className="d-flex flex-column w-100" onSubmit={(e) => finding(e)}>
        <label className="defaultLabel">Origem</label>
        <select
          id="numberSelect"
          className="select-bootstrap mt-1 mb-3"
          value={filter.origin}
          onChange={(e) => setFilter({ ...filter, origin: e.target.value })}
        >
          <option value="">Selecione a Origem</option>
          {config.summarize.map((value) => (
            <option value={value.origin}>{value.visualName}</option>
          ))}
        </select>

        <div className={analitcsStyle.inputContainer}>
          <label className="defaultLabel">Número</label>
          <input
            className="input-bootstrap mt-1 mb-3"
            type="text"
            placeholder="+5511912345678"
            value={filter.number}
            id="number"
            onChange={(e) => setFilter({ ...filter, number: e.target.value })}
          />
        </div>

        <div className="d-flex justify-content-between pe-2 mb-4">
          <div className={`${analitcsStyle.timerContainer} d-flex flex-column`}>
            <label>De</label>
            <input
              className="input-bootstrap mt-1"
              type="datetime-local"
              value={filter.date.from}
              id="searchdatefrom"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, from: e.target.value },
                })
              }
            />
          </div>

          <div className={`${analitcsStyle.timerContainer} d-flex flex-column`}>
            <label>Até</label>
            <input
              className="input-bootstrap mt-1"
              type="datetime-local"
              value={filter.date.to}
              id="searchdateto"
              onChange={(e) =>
                setFilter({
                  ...filter,
                  date: { ...filter.date, to: e.target.value },
                })
              }
            />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          {loading ? (
            <CircularProgress />
          ) : flag && data ? (
            <a href={data} className="linking">
              {console.log(data)}
              <Button
                variant="contained"
                sx={{ bgcolor: "#007bff;", ":hover": { bgcolor: "#005bff;" } }}
                disabled={filter.date.from && filter.date.to ? false : true}
                onClick={() => clearDownload()}
              >
                Download
              </Button>
            </a>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{ bgcolor: "#007bff;", ":hover": { bgcolor: "#005bff;" } }}
              disabled={filter.date.from && filter.date.to ? false : true}
              // onClick={() => { finding() }}
            >
              Pesquisar
            </Button>
          )}
        </div>
      </form>
    </div>
  );
}
