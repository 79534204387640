import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import CircularLoading from "../loadModules/circularLoading.js"
import SmallModal from "../UI/Modals/Small";
import ViewListIcon from '@mui/icons-material/ViewList';

const columns = [
  { field: "namemailing", headerName: "Nome", flex: 2 },
  { field: "filemailing", headerName: "Arquivo", flex: 2 },
  {
    field: "senddate", align: "center", headerAlign: "center", headerName: "Data", flex: 1, renderCell: (params) => (
      <a>
        {dateConversion(params.value)}
      </a>
    ),
  },
  { field: "numbermailing", headerName: "Carregados", flex: 1, align: "center", headerAlign: "center" },
  { field: "new", align: "center", headerAlign: "center", headerName: "Pendentes", flex: 0.5 },
  { field: "sent", align: "center", headerAlign: "center", headerName: "Enviados", flex: 0.5 },
  { field: "errors", align: "center", headerAlign: "center", headerName: "Erros", flex: 0.5 },
  { field: "_id", headerName: "ID", flex: 2 },
];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
  </GridToolbarContainer>
);

const dateConversion = (dateToConvert) => {
  return new Date(new Date(dateToConvert).getTime() + (3 * 60 * 60 * 1000)).toLocaleString()
}

export default function SendListTable({ sendList, loaded }) {
  var rows = [];

  if (sendList.length !== 0) {
    for (let i = 0; i < sendList.length; i++) {
      rows.push({ id: i + 1, ...sendList[i]});
    }
  }

  return (sendList.length === 0 || sendList.length === undefined) && (loaded === false) ? (
    <div className="compacting">
      <CircularLoading />
    </div>
  ) : (
    <div style={{ height: "110%", width: "100%" }}>
      <DataGrid
        sx={{
          ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
            color: "white"
          },
          ".MuiTablePagination-displayedRows": {
            color: "white"
          },
          ".MuiSelect-select, .MuiTablePagination-select": {
            color: "white"
          }
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
