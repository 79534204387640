import MessagesListTable from "./periodTable.jsx";
import RegisterMessageHSM from "./registerPeriod";

import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

import addCircle from "../../images/addCircle.svg";
import ModalCall from "../../components/tables/modalOpen";
import { toast } from "react-toastify";
import api from "../../services/api";

export default function Period() {
  // States
  const [blacklistAPI, setBlacklistAPI] = useState({});
  // End State

  //OnPage Load
  // useEffect(async () => {
  //   const res = await api
  //     .get("api/espresso/mensagens/", {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.length === 0) {
  //         toast.error("Erro #004: Sem resultado de busca");
  //       }
  //       setMessagesAPI(res.data);
  //       return;
  //     })
  //     .catch((err) => {
  //       toast.error("Erro #003: Falha ao acessar o banco de dados");
  //     });
  //   return;
  // }, []);
  // End Page Load//

  return (
    <div className="messages">
      <div className="msgToolBar">
        <div className="title">Periodo de Interações</div>
        <div className="rightToolbar">
          <ModalCall
            modalSize={{height:40,width:35}}
            buttonString="Adicionar"
            boxName="Registrar Número a Blocklist"
            icon={addCircle}
            className="send"
            styleButton={{
              bgcolor: "#1ABC9C",
              color: "white",
              padding: 1.3,
              fontSize: 14,
              fontWeight: 700,
              borderRadius: 3,
              boxShadow: 3,
              ":hover": { bgcolor: "#16a085" },
            }}
          >
            <RegisterMessageHSM />
          </ModalCall>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <MessagesListTable blacklist={blacklistAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
