import cdrStyles from './cdrPage.module.css';
import React, { useEffect, useState } from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import CdrLogTable from '../../components/tables/cdrLogTable';
import ActionBtn from '../../components/UI/actionbtn';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import api from '../../services/api';
import { toast } from 'react-toastify';
import DownloadByTime from './downloadByTime';
import { checkDate, config, maxDays } from '../../services/utils';
import DownloadModal from '../../components/UI/downloadModal';

const { checkDates } = config.application?.features;

export default function CdrLog() {
  const styleButton = {
    bgcolor: '#007bff;',
    color: 'white',
    padding: 1.1,
    fontSize: 16,
    fontWeight: 700,
    borderRadius: 2,
    boxShadow: 3,
    marginTop: 0,
    marginBottom: 0.2,
    marginLeft: 1,
    ':hover': { bgcolor: '#005bff;' },
  };

  const defaultCDRSearch = {
    from_dateTime: '',
    to_dateTime: '',
    origin: '',
  };

  const [CDRSearch, setCDRSearch] = useState({ ...defaultCDRSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [call, setCall] = useState(true);

  const handleChange = (type, value) => {
    let _CDRSearch = { ...CDRSearch };
    _CDRSearch[type] = value;
    setCDRSearch({ ..._CDRSearch });
  };

  const dateConversion = (dateToConvert) => {
    return new Date(new Date(dateToConvert).getTime() - 3 * 60 * 60 * 1000).toISOString();
  };

  const loadTables = async () => {
    setCall(false);
    if (!CDRSearch.from_dateTime || !CDRSearch.to_dateTime) {
      toast.warn('Favor inserir datas de pesquisa');
      setCall(true);
    }
    if (!!CDRSearch.from_dateTime && !!CDRSearch.to_dateTime) {
      if (checkDate(CDRSearch.to_dateTime)) {
        if (maxDays(CDRSearch.from_dateTime, CDRSearch.to_dateTime, 11)) {
          await api
            .get(
              `api/database/report/cdr_log_iteracoes?from_dateTime=${dateConversion(
                CDRSearch.from_dateTime
              )}&to_dateTime=${dateConversion(CDRSearch.to_dateTime)}&origin_ura=${
                CDRSearch.origin.length > 0 ? CDRSearch.origin : ''
              }`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
              }
            )
            .then((res) => {
              if (res.data.length === 0) {
                toast.error('Não há relatórios para esta data');
              } else {
                toast.success('Busca realizada com sucesso');
              }
              setCall(true);
              setRetornoAPI(res.data);
              return;
            })
            .catch((err) => {
              toast.error('Erro #003: Falha na pesquisa');
              console.log('Ocorreu um erro: ', err);
              setCall(true);
            });
        } else {
          setCall(true);
          toast.warn('As datas excedem o limite de 10 dias');
        }
      } else {
        setCall(true);
        toast.warn('Favor inserir data maior ou igual a 01/10/2022');
      }
    }
  };

  const cleanSearch = () => {
    setCDRSearch({ ...defaultCDRSearch });
    setRetornoAPI([]);
    toast.success('Os Campos foram limpos');
  };

  return (
    <div className={cdrStyles.cdrLog}>
      <div className="d-flex justify-content-between pe-4">
        <div className="titlePage">Detalhe de Logs</div>
        <div className="d-flex align-items-center">
          <div className={` d-flex align-items-center me-3 ${cdrStyles.containerCdrPageHeader}`}>
            <label className="defaultLabel me-2">Origin: </label>
            <select
              className="select-bootstrap"
              value={CDRSearch.origin}
              onChange={(e) => handleChange('origin', e.target.value)}
            >
              <option value="">Todos</option>
              {config.summarize.map((value) => (
                <option value={value.origin}>{value.visualName}</option>
              ))}
            </select>
          </div>

          <div className={` d-flex align-items-center me-3 ${cdrStyles.containerCdrPageHeader}`}>
            <label className="defaultLabel me-2">De: </label>
            <input
              type="dateTime-Local"
              value={CDRSearch.from_dateTime}
              id="searchdatefrom"
              onChange={(e) => handleChange('from_dateTime', e.target.value)}
              className="input-bootstrap"
            />
          </div>
          <div className={` d-flex align-items-center me-3 ${cdrStyles.containerCdrPageHeader}`}>
            <label className="defaultLabel me-2">Até: </label>
            <input
              type="dateTime-Local"
              value={CDRSearch.to_dateTime}
              id="searchdateto"
              onChange={(e) => handleChange('to_dateTime', e.target.value)}
              className="input-bootstrap"
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>

          {config.application.modules?.logs.download === true ? (
            <DownloadModal
              modalSize={{ height: 40, width: 30 }}
              buttonString={<DownloadIcon />}
              boxName="Pesquisa por Período"
              iconButton={false}
              styleButton={styleButton}
            >
              <div className="center">
                <DownloadByTime />
              </div>
            </DownloadModal>
          ) : null}
        </div>
      </div>
      <div className={cdrStyles.cdrBottomBox}>
        <StyledEngineProvider injectFirst>
          <CdrLogTable
            loading={call}
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: 'black', color: 'white' }}
          ></CdrLogTable>
        </StyledEngineProvider>
      </div>
    </div>
  );
}
