import tagStyle from "./TagCloud.module.css";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import NTagCloud from "react-tag-cloud";
import { toast } from "react-toastify";
import api from "../../services/api";
import CloudIcon from "@mui/icons-material/Cloud";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import { config } from "../../services/utils";
import CircularLoading from "../../components/loadModules/circularLoading";

function generateDynamicId() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }

  return id;
}

export default function TagCloud() {
  const _filter = {
    origin: "",
    remetente: "",
  };
  const [filter, setFilter] = useState(_filter);
  const [words, setWords] = useState([]);
  const [call, setCall] = useState(true);

  const queryData = async () => {
    setCall(false);
    const apiFilter = {};
    if (filter.origin !== "") apiFilter.origin = filter.origin;
    if (filter.remetente !== "") apiFilter.remetente = filter.remetente;
    api
      .get("api/database/report/cloudtag", {
        params: apiFilter,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        setWords(res.data);
        setCall(true);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
        setCall(true);
      });
  };

  const clearData = () => {
    setFilter(_filter);
    setWords([]);
    toast.success("Os Campos foram limpos");
  };

  // End Page Load//
  const tags = [];
  for (const i in words) {
    const style = {};
    if (i < words.length / 3) {
      switch (words[i].sentimento) {
        case -1:
          style.color = "#FE504FFF";
          break;
        case 0:
          style.color = "#12DD98FF";
          break;
        case 1:
          style.color = "#274C6CFF";
          break;
        default:
          style.color = "#D66925FF";
      }
      if (!words[i].sentimento) {
        style.color = "#D66925FF";
      }
      style.fontSize = 50;
    } else if (i < (words.length / 3) * 2) {
      switch (words[i].sentimento) {
        case -1:
          style.color = "#FE504FCC";
          break;
        case 0:
          style.color = "#12DD98CC";
          break;
        case 1:
          style.color = "#274C6CCC";
          break;
        default:
          style.color = "#D66925CC";
      }
      if (!words[i].sentimento) {
        style.color = "#D66925CC";
      }
      style.fontSize = 40;
    } else {
      switch (words[i].sentimento) {
        case -1:
          style.color = "#FE504F88";
          break;
        case 0:
          style.color = "#12DD9888";
          break;
        case 1:
          style.color = "#274C6C88";
          break;
        default:
          style.color = "#D6692588";
      }
      if (!words[i].sentimento) {
        style.color = "#D6692588";
      }
      style.fontSize = 20;
    }

    // console.log(words[i], i, style);
    tags.push(
      <div key={`tag-${i}-${generateDynamicId()}`} style={style}>
        {words[i].word}
      </div>
    );
  }
  return (
    <div className={tagStyle.cloudWords}>
      <div className="d-flex align-items-center justify-content-between pe-4">
        <p className="titlePage">Dashboard - Nuvem</p>
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center me-3 ${tagStyle.containerSelectHeader}`}
          >
            <label className="defaultLabel me-2">Origem:</label>
            <select
              className="select-bootstrap"
              value={filter.origin}
              onChange={(e) => setFilter({ ...filter, origin: e.target.value })}
            >
              <option defaultValue>Selecione a Origem</option>
              {config.summarize.map((value) => (
                <option value={value.origin}>{value.visualName}</option>
              ))}
            </select>
          </div>
          <div
            className={`d-flex align-items-center ${tagStyle.containerInputHeader} me-3`}
          >
            <label className="defaultLabel me-2">Remetente:</label>
            <input
              className="input-bootstrap"
              type="text"
              value={filter.remetente}
              id="remetente"
              onChange={(e) =>
                setFilter({ ...filter, remetente: e.target.value })
              }
            />
          </div>
          <div
            onClick={() => {
              clearData();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              queryData();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>{" "}
      </div>
      <div className={tagStyle.cloudTitle}>
        <CloudIcon />
        <span> Nuvem de Palavras</span>
      </div>
      <div className={tagStyle.cloudBox}>
        <StyledEngineProvider injectFirst>
          {call ? (
            <NTagCloud
              style={{
                fontFamily: "sans-serif",
                fontSize: 30,
                fontWeight: "bold",
                fontStyle: "italic",
                padding: 5,
                width: "100%",
                height: "100%",
              }}
            >
              {tags}
            </NTagCloud>
          ) : (
            <CircularLoading />
          )}
        </StyledEngineProvider>
      </div>
    </div>
  );
}
