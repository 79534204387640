import React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@material-ui/core";

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      fontSize: "calc(6px + 0.4vw)",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    },
  },
})(DataGrid);

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport
      csvOptions={{ delimiter: ";" }}
      printOptions={{ disableToolbarButton: true }}
    />
  </GridToolbarContainer>
);

const columns = [
  {
    field: "ConversationID",
    headerName: "Data e Hora",
    flex: 1,
  },
  { field: "Telefone", headerName: "Cliente", flex: 1 },
  { field: "Origen", headerName: "Telefone", flex: 1 },
  {
    field: "Fila",
    headerName: "Tagueamento",
    renderCell: (params) => {
      if (params.value === null) {
        return "System.Empty";
      }
      return params.value;
    },
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Inicio",
    headerName: "Resultado",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Fim",
    headerName: "Melhor Forma de contato",
    type: "dateTime",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => (
      <div>
        {params.value === null ? (
          "-"
        ) : (
          <div>
            <Tooltip title={new Date(params.value).toLocaleString()}>
              <span className="table-cell-trucate">
                {new Date(params.value).toLocaleString()}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    ),
  },
  {
    field: "Usuario",
    headerName: "Data de contato",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
  {
    field: "Status",
    headerName: "Periodo",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        {params.value ? (
          <div>{params.value}</div>
        ) : (
          <div>-</div>
        )}
      </div>
    ),
  },

  {
    field: "Avaliação",
    HeaderName: "Avaliação",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },
  {
    field: "Tentativas",
    HeaderName: "Tentativas",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <div>{params.value ? <div>{params.value}</div> : <div>-</div>}</div>
    ),
  },

];





export default function AttemptsConsolidatedTable(data) {

  return (
    <>
      <div style={{ height: "110%", width: "100%" }}>
        <StyledDataGrid
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
          }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={data}
          getRowId={(row) => {
            return row?.id;
          }}
          // @ts-ignore
          columns={columns}
        />
      </div>
    </>
  );
}
