import React, { useEffect } from 'react';
import api from '../api';
import { auth_URL, defineLoginGenesys } from '../utils';

function clearToken() {
  let now = new Date();
  let tomorrow = new Date(now.getTime() + 1 * 20 * 60 * 60 * 1000);
  if (!localStorage.getItem('ref') || new Date(localStorage.getItem('ref')) < now) {
    localStorage.setItem('ref', tomorrow);
    localStorage.removeItem('pcToken');
    localStorage.removeItem('token');
  }
}

export default function LoadingScreen() {
  let offScreenRedirect = '';
  if (localStorage.getItem('ghURL')) {
    offScreenRedirect = 'historyGenesys';
  }
  if (localStorage.getItem('widget')) {
    offScreenRedirect = 'screenLoging';
  }
  if (localStorage.getItem('scheduled')) {
    offScreenRedirect = 'scheduled';
  }
  useEffect(() => {
    clearToken();
    if (defineLoginGenesys()) window.open(`${auth_URL + offScreenRedirect}`, '_self');
    //else window.open(`${offScreenRedirect}`, '_self');
  }, []);

  return <div />;
}
