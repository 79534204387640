import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import hsmStyle from './screenHSM.module.css';
import { config, CRM_URL, PURECLOUD_URL, defineLoginGenesys } from '../../services/utils';
import { v4 as UwUid } from 'uuid';
import ParamLoading from '../../components/loadParams/loadingParams';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Link } from 'react-router-dom';
import EventIcon from '@mui/icons-material/Event';

export default function HSM({ showAgendamento }) {
  // ---------------- Constantes ----------------- //

  const { shAgentReturn, timedHSM } = config.application.features;
  const oneSenderEntry =
    config.fakeConfig.defaultBroker !== 'disable'
      ? config.fakeConfig.brokers[config.fakeConfig.defaultBroker].senders
      : [];

  const [phoneContact, setPhoneContact] = useState('');
  const [sender, setSender] = useState({});
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState({});
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [checkboxAgent, setCheckboxAgent] = useState({});
  const [toggle, setToggle] = useState(false);
  const [params, setParams] = useState([]);
  const [objectParams, setObjectParams] = useState({});
  const [dataParam, setDataParam] = useState({});
  const [callAPIOnline, setCallAPIOnline] = useState(true);
  const [agentDateTime, setAgentDateTime] = useState(0);
  const [queueName, setQueueName] = useState('');
  const [crmLock, setCrmLock] = useState(false);

  const [checker, setChecker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  // --------------------------------------------- //

  // ----------------- onLoad -------------------- //
  const isLoginGenesys = defineLoginGenesys();
  useEffect(() => {
    if (isLoginGenesys)
      api
        .get(`${PURECLOUD_URL}/users/${localStorage.getItem('userId')}/queues`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
          },
        })
        .then((res) => {
          if (res.data.entities.length == 0) {
            toast.warn('Usuario não possui filas cadastradas');
          } else {
            setQueues(res.data.entities);
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.code == 'authentication.required' ||
            error?.response?.data?.code == 'bad.credentials'
          ) {
            toast.warn('Sessão do genesys expirada');
            localStorage.removeItem('pcToken');
            reload(3000);
          } else {
            toast.error('Filas não encontradas');
          }
        });
    else {
      api
        .get(`${CRM_URL}/queue`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('CRM_token')}`,
          },
        })
        .then((res) => {
          setQueues(res.data.results);
          console.log(res);
        });
    }
    if (messages.length == 0 && config.fakeConfig.defaultBroker !== 'disable') {
      getMessages(JSON.stringify({ numberSelected: oneSenderEntry[0], broker: config.fakeConfig.defaultBroker }));
    }

    if (window.location.href.split('/').length === 6) {
      let getNumber = window.location.href.split('/')[5];
      let adapt = getNumber.slice('3');
      setPhoneContact(adapt);
      setCrmLock(true);
    }
  }, []);

  // --------------------------------------------- //

  // ----------------- Functions ----------------- //
  function agendamento() {
    setChecker(!checker);
    setAgentDateTime(0);
  }

  function queueAssign(value) {
    let settings = JSON.parse(value);
    setSelectedQueue(JSON.parse(value));
    setQueueName(settings.name);
  }

  function toUTC(insertDay) {
    let transfer = new Date(insertDay).toISOString();
    setAgentDateTime(transfer);
  }

  function handleCheck(event) {
    let _checkboxAgent = checkboxAgent;

    if (event) {
      _checkboxAgent.checked = true;
      _checkboxAgent.userId = localStorage.getItem('userId');
      _checkboxAgent.userName = localStorage.getItem('userName');
      setToggle(true);
    }
    if (!event) {
      _checkboxAgent.checked = false;
      _checkboxAgent.userId = null;
      _checkboxAgent.userName = null;
      setToggle(false);
    }
    setCheckboxAgent(_checkboxAgent);
  }

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
      },
      timer ? timer : 500
    );
  }

  function insertingParams() {
    const pdSelectedMessage = selectedMessage;
    if (!!pdSelectedMessage?.parametros) {
      if (!loading) {
        const paramscount = selectedMessage !== null ? Object.keys(pdSelectedMessage.parametros) : [];

        return paramscount.length ? (
          <div className={hsmStyle.parametersContainer}>
            {paramscount.map((item, idx) => (
              <div className={hsmStyle.containerInputParams}>
                <input
                  maxLength="300"
                  className="input-bootstrap"
                  placeholder={paramscount[idx] + ': ' + pdSelectedMessage.parametros[paramscount[idx]]}
                  key={idx}
                  onChange={(e) => {
                    let _params = [...params];
                    let _objectParams = objectParams;
                    _params[idx] = e.target.value;
                    _objectParams[pdSelectedMessage.parametros[paramscount[idx]]] = e.target.value;
                    setParams(_params);
                    setObjectParams(_objectParams);
                    quantity(e.target.value.length);
                  }}
                />
              </div>
            ))}
          </div>
        ) : null;
      }
      return <ParamLoading />;
    }
    return null;
  }

  function quantity(qnt) {
    if (qnt >= 300) {
      toast.warn('Limite de caracteres atingido (300 caracteres)');
    }
  }

  function getMessages(params) {
    const paramsParsed = JSON.parse(params);
    const numberSelected = paramsParsed.numberSelected;
    api
      .get(`api/espresso/mensagens/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        setCallAPIOnline(true);
        setMessages(
          res.data.filter((msg) => {
            return (
              ('' + numberSelected.namespaces).includes(msg.namespace) ||
              numberSelected.namespaces == '*' ||
              (msg.namespace == '*' && msg.broker === paramsParsed.broker)
            );
          })
        );
      })
      .catch((err) => {
        setCallAPIOnline(false);
        if (callAPIOnline) {
          toast.error('Erro #003: Falha no acesso ao banco de dados');
        }
        setTimeout(() => {
          getMessages(params);
        }, 20000);
      });
  }

  function handleMessage(value) {
    setSelectedMessage(value);
    setDataParam({});
    switchLoading(true);
  }

  function switchLoading() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  function handleSubmit(e) {
    setSending(true);
    e.preventDefault();

    let _sender = sender;
    if (oneSenderEntry.length === 1) {
      _sender = { numberSelected: oneSenderEntry[0], broker: config.fakeConfig.defaultBroker };
    }

    const pdSelectedMessage = selectedMessage;
    let dataParam = {};
    let columns = [];

    if (!!pdSelectedMessage?.parametros) {
      for (const key of Object.keys(pdSelectedMessage.parametros)) {
        columns.push(pdSelectedMessage.parametros[key]);
        dataParam[key.replace('{', '').replace('}', '')] = pdSelectedMessage.parametros[key];
      }
    }

    let contact = {
      phonecontact: '+55' + phoneContact,
      fila: queueName,
      createdate: new Date(),
      codecontact: UwUid(),
      status: 'new',
      detailmensagem: 'novo',
      userId: localStorage.getItem('userId'),
      userName: localStorage.getItem('userName'),
    };

    if (!!toggle) {
      contact = Object.assign(contact, checkboxAgent);
    }

    if (!!objectParams) {
      contact = Object.assign(contact, objectParams);
    }

    let oldPayload = {
      sender: _sender.numberSelected.mediaId,
      senduser: localStorage.getItem('userName'),
      origin: _sender.numberSelected.origin,
      codemailing: UwUid(),
      filemailing: 'Manual',
      namemailing: 'Manual_' + phoneContact,
      queue: queueName,
      typemensagem: 'HSM',
      broker: _sender.broker,
      cdmessage: selectedMessage.codemessage,
      body: selectedMessage.body,
      param: null,
      data: {
        namespace: selectedMessage.namespace,
        elementname: selectedMessage.elementname,
        body: selectedMessage.body,
        param: dataParam,
      },
      parametros: pdSelectedMessage?.parametros || {},
      contacts: [contact],
      columns: columns,
    };

    if (checker) {
      Object.assign(oldPayload, { scheduledDate: agentDateTime }); //add scheduledDate
      Object.assign(oldPayload, { queueName: queueName }); //add QueueName

      api
        .post('api/espresso/scheduledcontacts', oldPayload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data.ok == 'nok') {
            toast.warn('Cliente encontra-se em atendimento');
            setSending(false);
          }
          if (res.data.ok == 'ok') {
            toast.success('Agendamento Efetuado');
            setSending(false);
            reload(2000);
          }
        })
        .catch((err) => {
          toast.error((err.response && err.response.data.errmsg) || 'Internal server error');
          setSending(false);
        });
    } else {
      let resposta = api
        .post('api/espresso/mailing', oldPayload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data.ok == 'nok') {
            toast.warn('Cliente encontra-se em atendimento');
            setSending(false);
          }
          if (res.data.ok == 'ok') {
            toast.success('Mensagem enviada.');
            reload(2000);
            setSending(false);
          }
        })
        .catch((err) => {
          toast.error((err.response && err.response.data.resp.details) || 'Internal server error');
          setSending(false);
        });
    }
  }
  // --------------------------------------------- //

  return (
    <div className="hsmScreen">
      {showAgendamento ? null : timedHSM ? (
        <div className="classicAlign">
          <Link className="margin" to={'/app/widgettimedHSM'}>
            <div className="historyToolBar">
              <strong className="sendButton">
                <EventIcon />
              </strong>
            </div>
          </Link>
        </div>
      ) : null}

      <div className={hsmStyle.theBox}>
        {!showAgendamento ? <h3 className="titlePage">Enviar HSM</h3> : null}
        <form
          className="formSize"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="onScreenHSM">
            <div className="mb-3 pe-3">
              <label className="defaultLabel">Telefone do destinatário</label>

              <input
                disabled={crmLock}
                required
                maxLength="11"
                className="input-bootstrap mt-1"
                value={phoneContact}
                onChange={(e) => setPhoneContact(e.target.value)}
                placeholder="Exemplo: 11912345678"
                pattern="[0-9]{10,11}"
                title="Deve conter apenas numeros incluindo o DDD"
              ></input>
            </div>

            <div className="mb-3">
              <label className="defaultLabel mb-1">Retornar para a fila</label>

              <select
                className="select-bootstrap mt-1"
                id="register__profile"
                required
                onChange={(e) => queueAssign(e.target.value)}
              >
                <option defaultValue="" hidden>
                  {' '}
                  Selecione a fila de envio{' '}
                </option>
                {queues.map((value, idx) => (
                  <option value={JSON.stringify(value)} key={idx}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label className="defaultLabel">Número do remetente</label>

              <select
                disabled={oneSenderEntry.length === 1 ? true : false}
                className="select-bootstrap mt-1"
                id="register__profile"
                required
                onChange={(e) => {
                  setSender(JSON.parse(e.target.value));
                  getMessages(e.target.value, e.target);
                }}
              >
                {oneSenderEntry.length === 1 ? (
                  <option value={oneSenderEntry[0].numero}>{oneSenderEntry[0].numero}</option>
                ) : (
                  <option defaultValue="" hidden>
                    Selecione o número de envio
                  </option>
                )}
                {config.fakeConfig.defaultBroker !== 'disable'
                  ? oneSenderEntry.map((value, idx) => (
                      <option
                        value={JSON.stringify({
                          numberSelected: value,
                          broker: config.fakeConfig.defaultBroker,
                        })}
                        key={idx}
                      >
                        {value.numero}
                      </option>
                    ))
                  : config.fakeConfig.enabled.map((broker) =>
                      config.fakeConfig.brokers[broker].senders.map((value, idx) => (
                        <option
                          value={JSON.stringify({
                            numberSelected: value,
                            broker: broker,
                          })}
                          key={idx}
                        >
                          {value.numero}
                        </option>
                      ))
                    )}
              </select>
            </div>

            <div className="mb-3">
              <label className="defaultLabel">Mensagem</label>

              <select
                className="select-bootstrap mt-1"
                onChange={(e) => {
                  handleMessage(JSON.parse(e.target.value));
                  setParams([]);
                }}
              >
                <option defaultValue="" hidden>
                  {' '}
                  Selecione...{' '}
                </option>
                {messages.map((item, idx) => (
                  <option key={idx} value={JSON.stringify(item)}>
                    {item.codemessage}
                  </option>
                ))}
              </select>
            </div>

            <div className="msgText ">
              <label className="defaultLabel">Prévia da Mensagem</label>

              <TextareaAutosize
                className={`text-area-bootstrap mt-1 ${hsmStyle.sizeTextArea}`}
                placeholder="Escolha uma mensagem para carregar uma prévia"
                disabled
                value={selectedMessage !== null ? selectedMessage.body : ''}
              />
            </div>

            {insertingParams()}

            {shAgentReturn ? (
              <div className={hsmStyle.checkerDiv}>
                <label htmlFor={hsmStyle.retorno}>
                  Retornar para mim:
                  <input
                    type="checkbox"
                    id="retorno"
                    style={{ marginRight: '1vh' }}
                    onChange={(e) => handleCheck(e.target.checked)}
                    value={checkboxAgent.checked}
                  />
                  <span className={hsmStyle.toggleWrap} htmlFor="retorno">
                    {toggle ? (
                      <ToggleOnIcon
                        style={{
                          color: '#007bff',
                          fontSize: 'calc(12px + 5vw)',
                          width: 'calc(12px + 3vw)',
                        }}
                      />
                    ) : (
                      <ToggleOffIcon
                        style={{
                          color: '#ff0000',
                          fontSize: 'calc(12px + 5vw)',
                          width: 'calc(12px + 3vw)',
                        }}
                      />
                    )}
                  </span>
                </label>
              </div>
            ) : null}
          </div>

          {showAgendamento ? null : timedHSM ? (
            <div className={hsmStyle.checkerDiv}>
              <label htmlFor="agendamento">
                Agendar Envio:
                <input type="checkbox" id="agendamento" style={{ marginRight: '1vh' }} onChange={() => agendamento()} />
                <span className={hsmStyle.toggleWrap} htmlFor="agendamento">
                  {checker ? (
                    <ToggleOnIcon
                      style={{
                        color: '#007bff',
                        fontSize: 'calc(12px + 5vw)',
                        width: 'calc(12px + 3vw)',
                      }}
                    />
                  ) : (
                    <ToggleOffIcon
                      style={{
                        color: '#ff0000',
                        fontSize: 'calc(12px + 5vw)',
                        width: 'calc(12px + 3vw)',
                      }}
                    />
                  )}
                </span>
              </label>
            </div>
          ) : null}

          {checker ? (
            <div className="d-flex flex-column justify-content-center">
              <label className="defaultLabel">Data e Hora</label>
              <div className={hsmStyle.timerContainer}>
                <input
                  type="datetime-local"
                  className="input-bootstrap"
                  onChange={(e) => {
                    toUTC(e.target.value);
                  }}
                />
              </div>
            </div>
          ) : null}

          <br />
          {/* <Grid container justifyContent="space-evenly"> */}
          <div className="d-flex justify-content-center">
            {sending ? (
              <ParamLoading />
            ) : (
              <button
                disabled={
                  !(
                    phoneContact &&
                    selectedQueue &&
                    selectedMessage &&
                    params.length === (selectedMessage?.parametros ? Object.keys(selectedMessage.parametros).length : 0)
                  )
                }
                type="submit"
                className="btn btn-primary containerBtn-4 d-flex align-items-center justify-content-center"
              >
                <SendIcon className="me-2" style={{ color: 'white', marginRight: '1vh' }} />
                {checker ? <span>Agendar</span> : <span>Enviar</span>}
              </button>
            )}
          </div>
          {/* </Grid> */}
        </form>
      </div>
    </div>
  );
}
