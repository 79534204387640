import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import "./gb.css"

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: '#2980b9' }} />;

export default function SearchingSelect({ opts, fn }) {
  return (
      <Autocomplete
        onChange={(e, newValue) => {
          fn("listName", newValue)
        }}
        id="checkboxes-tags-demo"
        options={opts}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props} className="listSelect">
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} />}
      />
  );
}