import React from "react";
import ReactDOM from "react-dom";
import {
  getRescue,
  getConfig,
  loginLW,
  loginPC,
  defineLoginGenesys,
} from "./services/utils";
import { AuthService } from "./services/loginLw";
const rootEl = document.getElementById("root");

let render = () => {
  const MainApp = require("./App").default;
  ReactDOM.render(<MainApp />, rootEl);
};

/**
 * Regra de autenticação está dentro de defineLoginGenesys.
 * Caso seja logado pelo genesys, precisa adicionar a URL a estes campos
 */

let isLoginGenesys = defineLoginGenesys();
// console.log(isLoginGenesys);
if (isLoginGenesys) {
  getConfig().then(() => {
    loginPC()
      .then(() => {
        render();
      })
      .catch((e) => {
        getRescue(false);
      });
  });
} else {
  AuthService.getUser()
    .then((user) => {
      // console.log(user);
      if (!user) AuthService.login();
      else
        loginLW().then(() => {
          getConfig().then(() => {
            render();
          });
        });
    })
    .catch((e) => {
      console.log(e);
    });
}

/*import React from 'react';
import ReactDOM from 'react-dom';
import { getRescue, getConfig, loginPC } from './services/utils';

const rootEl = document.getElementById('root');

let url = process.env.REACT_APP_URL;




let render = () => {
  const MainApp = require('./App').default;
  loginPC().then(() => {
    ReactDOM.render(<MainApp />, rootEl);
  });
};

getConfig()
  .then(() => {
    render();
  })
  .catch((e) => {
    getRescue(false);
  });
*/
