import summarizeStyle from "./summarize.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import HistoryTable from "../../components/tables/bolsaoLogTable.js";
import api from "../../services/api";
import { toast } from "react-toastify";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";

export default function Summarize() {
  const defaultHSMSearch = {
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    entry_from: "",
    entry_to: "",
    columns: {},
  };

  // useEffect(async () => {
  //   loadTables();
  // }, []);

  const [HSMSearch, setHSMSearch] = useState({ ...defaultHSMSearch });
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [call, setCall] = useState(true);

  const handleChange = (type, value) => {
    let _HSMSearch = { ...HSMSearch };
    _HSMSearch[type] = value;
    setHSMSearch({ ..._HSMSearch });
  };

  const loadTables = async () => {
    setCall(false);
    let hsmReq = {};

    if (HSMSearch.entry_from && HSMSearch.entry_to) {
      hsmReq = {
        entry_from: new Date(HSMSearch.entry_from),
        entry_to: new Date(HSMSearch.entry_to),
      };
    }

    await api
      .post("api/inbound/report/getBolsaoHistory", hsmReq, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Não há relatórios para esta data");
        } else {
          toast.success("Busca realizada com sucesso");
        }
        setCall(true);
        setRetornoAPI(res.data);
        return;
      })
      .catch((err) => {
        setCall(true);
        toast.error("Erro #003: Falha na pesquisa");
      });
  };

  const cleanSearch = () => {
    setHSMSearch({ ...defaultHSMSearch });
    setRetornoAPI({ ...[] });
    toast.success("Os Campos foram limpos");
  };

  return (
    <div className={summarizeStyle.summarize}>
      <div className="d-flex justify-content-between">
        <div className="titlePage">Relatórios do Bolsão</div>
        <div className="d-flex pe-3">
          <div className="d-flex align-items-center">
            <label className="defaultLabel me-2">De: </label>
            <div className={summarizeStyle.containerInputHeader}>
              <input
                type="dateTime-Local"
                value={HSMSearch.entry_from}
                id="searchdatefrom"
                className="input-bootstrap"
                onChange={(e) => handleChange("entry_from", e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex align-items-center ms-4 me-3">
            <label className="defaultLabel me-2">Até: </label>
            <input
              className="input-bootstrap"
              type="dateTime-Local"
              value={HSMSearch.entry_to}
              id="searchdateto"
              onChange={(e) => handleChange("entry_to", e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              loadTables();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className={summarizeStyle.sumBottomBox}>
        <StyledEngineProvider injectFirst>
          <HistoryTable
            loading={call}
            retornoAPI={retornoAPI}
            styleButton={{ bgcolor: "black", color: "white" }}
          ></HistoryTable>
        </StyledEngineProvider>
      </div>
    </div>
  );
}
