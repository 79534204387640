import Chart from "react-apexcharts";

export default function UpBarChart({ dados }) {
  let series = [];
  const dadosTotal = {
    janeiro: 0,
    fevereiro: 0,
    março: 0,
    abril: 0,
    maio: 0,
    junho: 0,
    julho: 0,
    agosto: 0,
    setembro: 0,
    outubro: 0,
    novembro: 0,
    dezembro: 0,
  };

  dados.map((d) => {
    dadosTotal.janeiro += d.months.jan ?? 0;
    dadosTotal.fevereiro += d.months.fev ?? 0;
    dadosTotal.março += d.months.mar ?? 0;
    dadosTotal.abril += d.months.abr ?? 0;
    dadosTotal.maio += d.months.mai ?? 0;
    dadosTotal.junho += d.months.jun ?? 0;
    dadosTotal.julho += d.months.jul ?? 0;
    dadosTotal.agosto += d.months.ago ?? 0;
    dadosTotal.setembro += d.months.set ?? 0;
    dadosTotal.outubro += d.months.out ?? 0;
    dadosTotal.novembro += d.months.nov ?? 0;
    dadosTotal.dezembro += d.months.dez ?? 0;
  });

  let dtX = Object.keys(dadosTotal);
  let dtY = Object.values(dadosTotal);
  for (let i = 0; i < dtX.length; i++) {
    series.push({ x: dtX[i], y: dtY[i] });
  }

  let config = {
    options: {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "bottom",
          },
        },
      },

      legend: {
        show: false,
      },
      colors: ["#007BFF", "#26979E", "#30ABBC", "#456099"],

      tooltip: {
        y: {
          formatter: undefined,
          title: {
            formatter: () => "Conversas neste mês: ",
          },
        },
      },

      chart: {
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
    },
  };

  return (
    <Chart
      options={config.options}
      series={[{ data: series }]}
      // series={config.series}
      type="bar"
      height={`100%`}
    />
  );
}
