import sendListStyle from "./sendList.module.css";
import React, { useEffect, useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import SendListTable from "../../components/tables/sendListTable.js";
import send from "../../images/send.svg";
import ModalCall from "../../components/tables/modalOpenSENDLIST";
import api from "../../services/api";
import { toast } from "react-toastify";
import ModalHSM from "./register/registerList";

const { v4: uuidv4 } = require("uuid");

export default function SendList() {
  const [sendListAPI, setSendListAPI] = useState({});
  const [exec, setExec] = useState([true]);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await api
      .get("api/espresso/mailings", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setSendListAPI(res.data);
        setLoaded(true);
        setTimeout(() => {
          setExec(!exec);
        }, 10000);
      })
      .catch((err) => {
        toast.error("Erro #003: Falha no acesso ao banco de dados");
      });
  }, [exec]);

  return (
    <div className={sendListStyle.sendList}>
      <div className={sendListStyle.toolBar}>
        <div className="titlePage">Lista de Envios:</div>
        <div className={sendListStyle.rightSide}>
          <ModalCall
            open={open}
            setOpen={setOpen}
            buttonString="Enviar"
            boxName="Enviar Mensagem"
            icon={send}
            className={sendListStyle.send}
          >
            <ModalHSM />
        </ModalCall>
        </div>
      </div>

      <div className={sendListStyle.slBottomBox}>
        <StyledEngineProvider injectFirst>
          <SendListTable sendList={sendListAPI} loaded={loaded} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
