import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CSSWeek from "../registerPage/week.module.css";
import registerStyle from "../registerPage/register.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, ml: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({ schedule, setSchedule }) {
  const [value, setValue] = useState(0);
  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scheduleChange = (attr, day, value) => {
    let _schedule = { ...schedule };

    if (attr === "startingHour") {
      Object.assign(_schedule[day], {
        startingHour: value.split(":")[0],
        startingMinute: value.split(":")[1],
        labelStartingTime: value,
      });
    }
    if (attr === "endingHour") {
      Object.assign(_schedule[day], {
        endingHour: value.split(":")[0],
        endingMinute: value.split(":")[1],
        labelEndingTime: value,
      });
    }
    if (attr === "closed") {
      Object.assign(_schedule[day], { closed: value });
    }

    setSchedule({ ..._schedule });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "100%",
        width: "90%",
        margin: "1.0rem",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        <Tab label="Domingo" {...a11yProps(0)} />
        <Tab label="Segunda" {...a11yProps(1)} />
        <Tab label="Terça" {...a11yProps(2)} />
        <Tab label="Quarta" {...a11yProps(3)} />
        <Tab label="Quinta" {...a11yProps(4)} />
        <Tab label="Sexta" {...a11yProps(5)} />
        <Tab label="Sábado" {...a11yProps(6)} />
      </Tabs>

      {week.map((day, idx) => (
        <TabPanel value={value} index={idx}>
          <div className={`${CSSWeek.weeklySpace} mb-4`}>
            <div className={CSSWeek.weekLabels}>
              <label className="defaultLabel">Horário de Inicio</label>
            </div>
            <input
              value={schedule[day]?.labelStartingTime}
              type="time"
              className="time-bootstrap mt-1"
              onChange={(e) => {
                scheduleChange("startingHour", day, e.target.value);
              }}
            />
          </div>

          <div className={`${CSSWeek.weeklySpace} mb-4`}>
            <div className={CSSWeek.weekLabels}>
              <label className="defaultLabel">Horário de Finalização</label>
            </div>
            <input
              value={schedule[day]?.labelEndingTime}
              type="time"
              className="time-bootstrap mt-1"
              onChange={(e) => {
                scheduleChange("endingHour", day, e.target.value);
              }}
            />
          </div>

          <div className={CSSWeek.weeklySpace}>
            <div className={CSSWeek.weekLabels}>
              <label className="defaultLabel mb-2">Ura esta aberta: </label>
            </div>
            <label
              htmlFor="allDay"
              index={idx}
              className={`${
                schedule[day]?.closed
                  ? `${CSSWeek.unchecked}`
                  : `${CSSWeek.checked}`
              }`}
            >
              {`${schedule[day]?.closed ? "Não" : "Sim"}`}
            </label>
            <input
              className={registerStyle.specific}
              type="checkbox"
              id="allDay"
              onChange={(e) => scheduleChange("closed", day, e.target.checked)}
            />
          </div>
        </TabPanel>
      ))}
    </Box>
  );
}
