import scheduleStyle from "../registerPage/register.module.css";
import { useState, useEffect } from "react";
import VerticalTabs from "./week";
import { toast } from "react-toastify";
import api from "../../../services/api";

export default function ScheduleRegister({ _id }) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [reopening, setReopening] = useState("");
  const [timeout, setTimeOut] = useState(0);
  const [schedule, setSchedule] = useState({
    sunday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    monday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    tuesday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    wednesday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    thursday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    friday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
    saturday: {
      startingHour: "00",
      startingMinute: "00",
      labelStartingTime: "00:00",
      labelEndingTime: "23:59",
      endingHour: "23",
      endingMinute: "59",
      closed: false,
    },
  });

  useEffect(() => {
    async function editSchedule() {
      await api
        .get("api/espresso/workschedule", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          let apiReturn = res.data;
          apiReturn = apiReturn.filter((search) => search._id == _id);
          setName(apiReturn[0].name);
          setMessage(apiReturn[0].afterHoursMessage);
          setReopening(apiReturn[0].afterHoursEndingMessage);
          setTimeOut(apiReturn[0].afterHoursCooldown);
          setSchedule(apiReturn[0].weekDays);
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }

    if (!!_id) {
      editSchedule();
    }
  }, []);

  async function submitForm(event) {
    event.preventDefault();
    event.persist();

    let body = {
      name: name,
      afterHoursCooldown: timeout,
      afterHoursMessage: message,
      afterHoursEndingMessage: !!reopening ? reopening : null,
      weekDays: schedule,
    };

    if (!!_id) {
      Object.assign(body, { _id: _id });

      await api
        .put("api/espresso/workschedule", body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          toast.success("Schedule Editado com sucesso");
          reload(3000);
        })
        .catch(() => {
          toast.error("Algo deu errado durante o cadastro");
        });
    } else {
      await api
        .post("api/espresso/workschedule", body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then(() => {
          toast.success("Data cadastrada com sucesso");
          reload(3000);
        })
        .catch(() => {
          toast.error("Algo deu errado durante o cadastro");
        });
    }
  }

  function reload(timer) {
    setTimeout(
      function () {
        window.location.reload();
      },
      timer ? timer : 2000
    );
  }

  function timeCalculation(selected) {
    const selectOptions = [5, 10, 15, 30];
    let selector = selected;

    if (selector) {
      for (let i = 60; i < 721; i = i + 30) {
        selectOptions.push(i);
      }
    }

    if (selector === false) {
      for (let i = 60; i < 2881; i = i + 60) {
        selectOptions.push(i);
      }
    }

    return selectOptions.map((time, idx) => (
      <option className="centerDrop" value={time} key={idx}>
        {toHour(time)}
      </option>
    ));
  }

  function toHour(time) {
    if (time >= 60 && time % 60 != 0) {
      return ("0" + (time - 30) / 60).slice(-2) + ":30";
    }
    if (time >= 60 && time % 60 == 0) {
      return ("0" + time / 60).slice(-2) + ":00";
    }
    return "00:" + ("0" + time).slice(-2);
  }

  return (
    <div className={scheduleStyle.containerModal}>
      <form
        onSubmit={(e) => {
          submitForm(e);
        }}
      >
        <div className="d-flex flex-column mb-3 pe-4">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Nome</label>
          </div>
          <input
            className="input-bootstrap"
            value={name}
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Ex: Schedule domingo"
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Mensagem</label>
          </div>
          <textarea
            placeholder="Não estamos no horário de atendimento, retornaremos mais tarde..."
            className="text-area-bootstrap"
            rows={3}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Mensagem de reabertura</label>
          </div>
          <textarea
            className="text-area-bootstrap"
            value={reopening}
            onChange={(e) => setReopening(e.target.value)}
            placeholder="Voltamos com o atendimento, caso ainda queira nos envie um oii....."
          />
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="containerLabel mb-2">
            <label className="defaultLabel">Tempo de Recarga Afterhours</label>
          </div>
          <select
            className="select-bootstrap"
            required
            onChange={(e) => setTimeOut(e.target.value)}
            value={timeout}
          >
            <option value={null} hidden>
              Selecione uma opção
            </option>
            {timeCalculation(false)}
          </select>
        </div>

        <div className={scheduleStyle.weekBox}>
          <VerticalTabs schedule={schedule} setSchedule={setSchedule} />
        </div>

        <div className="d-flex justify-content-center my-4">
          <button className="btn btn-primary containerBtn-4" type="submit">
            {" "}
            Salvar{" "}
          </button>
        </div>
      </form>
    </div>
  );
}
