import TagsTable from "./TagsTable.js";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import addCircle from "../../images/addCircle.svg";
import ModalCall from "../../components/tables/modalOpen";
import { toast } from "react-toastify";
import api from "../../services/api";
import TagsEdit from "./TagsEdit";

export default function Tags() {
  // States

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState({
    includes: true,
    sentimento: 0,
    word: "",
  });

  // End State

  //OnPage Load
  useEffect(() => {
    api
      .get("api/database/tag/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // if (res.data.length === 0) {
        //   toast.error('Erro #004: Sem resultado de busca');
        // }
        setTags(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
  }, []);
  // End Page Load//

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-between w-100 ">
        <p className="titlePage">Palavras</p>
        <div className="me-4">
          <ModalCall
            modalSize={{ height: 40, width: 40 }}
            buttonString="Adicionar"
            boxName="Adicionar Palavra"
            icon={addCircle}
            styleButton={{
              bgcolor: "#007bff",
              color: "white",
              padding: 1,
              fontSize: 14,
              fontWeight: 600,
              borderRadius: 2,
              boxShadow: 3,
              marginTop: 0,
              marginBottom: 0.2,
              marginLeft: 1,
              ":hover": { bgcolor: "#005bff" },
            }}
            onClose={(e) => {
              setNewTag({ includes: true, sentimento: 0, word: "" });
            }}
          >
            <div className="center">
              <TagsEdit selected={newTag} />
            </div>
          </ModalCall>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <TagsTable tags={tags} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
