import React from "react";
import summarizeStyle from "./summarize.module.css";
import { StyledEngineProvider } from "@mui/material/styles";
import HibernationStatusTable2 from "../../components/tables/hibernationNumbers2";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import ActionBtn from "../../components/UI/actionbtn";
import { toast } from "react-toastify";
import api from "../../services/api";
import { checkDate, config, maxDays } from "../../services/utils";

const defaultCDRSearch = {
  from_dateTime: "",
  to_dateTime: "",
  origin: "",
};
const dateConversion = (dateToConvert) => {
  return new Date(
    new Date(dateToConvert).getTime() - 3 * 60 * 60 * 1000
  ).toISOString();
};

export default function ReportHibernation2() {
  const [CDRSearch, setCDRSearch] = React.useState({ ...defaultCDRSearch });
  const [retornoAPI, setRetornoAPI] = React.useState([]);
  const [call, setCall] = React.useState(true);

  const handleChange = (type, value) => {
    let _CDRSearch = { ...CDRSearch };
    _CDRSearch[type] = value;
    setCDRSearch({ ..._CDRSearch });
  };
  const loadTables = async () => {
    setCall(false);
    if (!CDRSearch.from_dateTime || !CDRSearch.to_dateTime) {
      toast.warn("Favor inserir datas de pesquisa");
      setCall(true);
    }
    if (!!CDRSearch.from_dateTime && !!CDRSearch.to_dateTime) {
      if (checkDate(CDRSearch.to_dateTime)) {
        if (maxDays(CDRSearch.from_dateTime, CDRSearch.to_dateTime, 11)) {
          await api
            .get(
              `api/database/report/hibernacao?date_start=${dateConversion(
                CDRSearch.from_dateTime
              )}&date_end=${dateConversion(CDRSearch.to_dateTime)}&origin=${
                CDRSearch.origin.length > 0 ? CDRSearch.origin : ""
              }`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              if (res.data.length === 0) {
                toast.error("Não há relatórios para esta data");
              } else {
                toast.success("Busca realizada com sucesso");
              }
              setCall(true);
              setRetornoAPI(res.data);
              return;
            })
            .catch((err) => {
              toast.error("Erro #003: Falha na pesquisa");
              console.log("Ocorreu um erro: ", err);
              setCall(true);
            });
        } else {
          setCall(true);
          toast.warn("As datas excedem o limite de 10 dias");
        }
      } else {
        setCall(true);
        toast.warn("Favor inserir data maior ou igual a 01/10/2022");
      }
    }
  };

  const cleanSearch = () => {
    setCDRSearch({ ...defaultCDRSearch });
    setRetornoAPI([]);
    toast.success("Os Campos foram limpos");
  };

  return (
    <div>
      <div className={summarizeStyle.summarize}>
        <div className="d-flex align-items-center justify-content-between pe-3 mt-2 mb-1">
          <p className="titlePage">Relatório Consolidado</p>
          <div className="d-flex align-items-center">
            <div
              className={`d-flex align-items-center ${summarizeStyle.containerSelectHeader} me-3`}
            >
              <label className="defaultLabel me-2">Origem: </label>
              <select
                className="select-bootstrap"
                value={CDRSearch.origin}
                onChange={(e) => handleChange("origin", e.target.value)}
              >
                <option value="">Todos</option>
                {config.summarize.map((value) => (
                  <option value={value.origin}>{value.visualName}</option>
                ))}
              </select>
            </div>

            <div
              className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
            >
              <label className="defaultLabel me-2">De: </label>
              <input
                className="input-bootstrap"
                type="dateTime-Local"
                value={CDRSearch.from_dateTime}
                id="searchdatefrom"
                onChange={(e) => handleChange("from_dateTime", e.target.value)}
              />
            </div>
            <div
              className={`d-flex align-items-center me-3 ${summarizeStyle.containerTimerHeader}`}
            >
              <label className="defaultLabel me-2">Até: </label>
              <input
                className="input-bootstrap"
                type="dateTime-Local"
                value={CDRSearch.to_dateTime}
                id="searchdateto"
                onChange={(e) => handleChange("to_dateTime", e.target.value)}
              />
            </div>
            <div
              onClick={() => {
                cleanSearch();
              }}
            >
              <ActionBtn color="yellow" icon={<SearchOffIcon />} />
            </div>

            <div
              onClick={() => {
                loadTables();
              }}
            >
              <ActionBtn color="green" icon={<SearchIcon />} />
            </div>
          </div>
        </div>
        <div className={summarizeStyle.sumBottomBox}>
          <StyledEngineProvider injectFirst>
            <HibernationStatusTable2 data={retornoAPI} />
          </StyledEngineProvider>
        </div>
      </div>
    </div>
  );
}
