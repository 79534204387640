import { config } from "../utils";

export const CDRMenus = [
    {
        key:"reportCDRs",
        label:"Iterações",
        route:"/app/summarize/CdrItr",
    },
    {
        key:"reportCDRs",
        label:"Logs",
        route:"/app/summarize/CdrLog",
    }
];

export const HSMSMSMenus = [
    {
        key:"reportHSM",
        label:"HSM por Lista",
        route:"/app/summarize/hsmlist"
    },
    {
        key:"reportHSM",
        label:"HSM por Periodo",
        route:"/app/summarize/hsm"
    },
    {
        key:"reportSMS",
        label:"SMS por Lista",
        route:"/app/summarize/smslist"
    },
    {
        key:"reportSMS",
        label:"SMS por Periodo",
        route:"/app/summarize/sms"
    }
]

export const sentimentoMenus = [
    {
        key:"reportFeelingA",
        label:"Analítico",
        route:"/app/summarize/SentimentoA"
    },
    {
        key:"reportFeelingC",
        label:"Consolidado",
        route:"/app/summarize/SentimentoC"
    }
];

export const reclameAquiMenus = [
    {
        key:"reportServiceFlow",
        label:"Fluxo de Atendimento",
        route:"/app/summarize/service-flow"
    },
    {
        key:"reportMailingStatus",
        label:"Status Mailing",
        route:"/app/summarize/mailing-status"
    }
];

export const hasReclameAquiItems = reclameAquiMenus.some((item)=> config.application.menus?.[item.key]);

export const hasSentimentoItems = sentimentoMenus.some((item)=> config.application.menus?.[item.key]);

export const hasCDRItems = CDRMenus.some((item)=>  config.application.menus?.[item.key] );

export const hasHSMSMSItems = HSMSMSMenus.some((item)=> config.application.menus?.[item.key]);

export const hasReportsItems = hasReclameAquiItems || hasSentimentoItems ||hasCDRItems || hasHSMSMSItems;