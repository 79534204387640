import api from './api';
import { toast } from 'react-toastify';
import { AuthService } from './loginLw';

var redirect = window.location.origin;

export let config = {};
export let auth_URL;
export const PURECLOUD_URL = 'https://api.mypurecloud.com/api/v2';
export const CRM_URL = 'https://crm.espressolw.com/api';

// ---------- Histories supported archives ------------- //

export const images = ['.jpeg', '.gif', '.png', '.bmp', '.jpg'];
export const video = ['.amr', '.mpeg', '.mp4'];
export const audio = [
  '.basic',
  '.L24',
  '.ogg',
  '.vorbis',
  '.vnd',
  '.wave',
  '.3gpp',
  '.3gpp2',
  '.ac3',
  '.webm',
  '.audio',
  '.audio',
  '.mp3',
  '.mpga',
  '.voice',
  '.oga',
];
export const arquivo = ['.doc', '.docx', '.pdf', '.xlsx', '.xls', '.csv', '.txt', '.pptx', '.ppt'];

// ----------------------------------------------------- //

// ---------- Alterations call backend apis ------------- //

export async function editHibernation(_id) {
  return await api
    .get(`api/espresso/hibernation/find/${_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => toast.error((err.response && err.response.data.msg) || 'Ocorreu um erro'));
}

export async function dellHibernation(_id) {
  if (window.confirm('Deseja mesmo Excluir ?')) {
    await api
      .post(
        `api/espresso/hibernation/delete/${_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        //Retornando mensagem de sucesso mesmo sem confirmar a resposta.
        toast.success('Deletando Mensagem Aguarde!');
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        toast.error((err.response && err.response.data.msg) || 'Ocorreu um erro');
      });
  }
}

export async function editCell(codemessage) {
  return await api
    .get(`api/espresso/mensagens/${codemessage}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then((res) => {
      // console.log("editCell", res.data);
      return res.data;
    })
    .catch((err) => toast.error((err.response && err.response.data.msg) || 'Ocorreu um erro'));
}

export async function dellCell(codemessage) {
  if (window.confirm('Deseja mesmo Excluir ?')) {
    await api
      .post(
        `api/espresso/mensagensDEL/${codemessage}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        toast.success('Deletando Mensagem Aguarde!');
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => toast.error((err.response && err.response.data.msg) || 'Ocorreu um erro'));
  }
}

export async function dellTab(rowkey) {
  const fullInfo = await editTab(rowkey);
  if (window.confirm('Deseja mesmo Excluir ?')) {
    if (fullInfo.nivel == 5) {
      async function delCodes() {
        let wpcodes = [];
        let gotAll = false;
        let i = 0;
        while (!gotAll) {
          i++;
          await api
            .get(`${PURECLOUD_URL}/routing/wrapupcodes?pageSize=200&pageNumber=${i}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
              },
            })
            .then((res) => {
              gotAll = !res.data.entities || res.data.entities.length === 0;
              if (!gotAll) wpcodes = [...res.data.entities];
            })
            .catch((err) => {
              gotAll = true;
              toast.error(err.message || 'Erro ao deletar');
            });
        }

        let codesToDel = wpcodes.filter((wpcode) => {
          return wpcode.name.toLowerCase().indexOf(fullInfo.codigo.toLowerCase()) > -1;
        });

        for (const del of codesToDel)
          api
            .delete(`${PURECLOUD_URL}/routing/wrapupcodes/${del.id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
              },
            })
            .then((res) => {})
            .catch((err) => toast.error(err.message || 'Internal server error :' + err));
      }
      delCodes();
    }

    return await api
      .delete(`${PURECLOUD_URL}/flows/datatables/${config.application.client.dataTableID}/rows/${rowkey}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
        },
      })
      .then((res) => {
        toast.success('Deletando Tabulação Aguarde!');
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => toast.error((err.response && err.response.data.msg) || 'Erro ao deletar'));
  }
}

export async function editTab(rowkey) {
  return await api
    .get(`${PURECLOUD_URL}/flows/datatables/${config.application.client.dataTableID}/rows/${rowkey}?showbrief=false`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('pcToken')}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => toast.error(err.message || 'Erro ao editar'));
}

export async function loginPC() {
  let pcToken = getParameterByName('access_token');

  if (!!pcToken && pcToken !== '') {
    localStorage.setItem('pcToken', pcToken);

    let token = localStorage.getItem('pcToken');
    if (!!token) {
      return await api
        .post('/api/auth/pcauthenticate', { token }, { headers: { 'Content-Type': 'application/json' } })
        .then((userData) => {
          localStorage.setItem('token', userData.data.token);
          localStorage.setItem('userId', userData.data.userId);
          localStorage.setItem('userName', userData.data.userName);
          localStorage.setItem('userProfile', userData.data.userProfile);
          localStorage.setItem('HSMs', userData.data.HSMs);
          return;
        })
        .catch((err) => {
          throw (err.response && err.response.data.token) || 'E-mail e/ou senha invalidos';
          return;
        });
    }
  }
}

export async function loginLW() {
  try {
    let lwToken = await AuthService.getAccessToken();
    let userData = await api.post(
      '/api/auth/lwauthenticate',
      { token: lwToken },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    let now = new Date();
    let tomorrow = new Date(now.getTime() + 1 * 20 * 60 * 60 * 1000);
    localStorage.setItem('ref', tomorrow);
    localStorage.setItem('token', userData.data.token);
    localStorage.setItem('CRM_token', lwToken);
    localStorage.setItem('userId', userData.data.userId);
    localStorage.setItem('userName', userData.data.userName);
    localStorage.setItem('userProfile', userData.data.userProfile);
    localStorage.setItem('HSMs', userData.data.HSMs);
    return await Promise.resolve(userData);
  } catch (err) {
    throw (err.response && err.response.data.token) || 'E-mail e/ou senha invalidos LOGIN LIKEWATER';
  }
}

// ----------------------------------------------------- //

// ---------- Midia Interactions Functions ------------- //

export const uploadMediatoBucket = async (filetoUpload) => {
  var formData = new FormData();
  formData.append('file', filetoUpload);
  // console.log("filetoUpload: ", filetoUpload);
  // console.log("formData: ", formData);

  const head = {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  return await api.post('/api/espresso/uploadFile', formData, { headers: head }).then((res) => {
    return res.data;
  });
};
// ----------------------------------------------------- //

// ----------------- Arthur Requests ------------------- //

export const forTheKing = async (swordHandle) => {
  return api.post(`/api/inbound/mediatoArthur`, swordHandle, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

// ----------------------------------------------------- //

// -------------- Genesys History Routing -------------- //

export function isAuthenticate() {
  let token = localStorage.getItem('token');
  let pcToken = localStorage.getItem('pcToken');
  let userProfile = localStorage.getItem('userProfile');
  let userId = localStorage.getItem('userId');
  let userName = localStorage.getItem('userName');
  let HSMs = localStorage.getItem('HSMs');
  let logged = false;

  const actData = new Date(localStorage.getItem('ref')) > new Date();
  const pathname = window.location.pathname;
  if (defineLoginGenesys())
    logged =
      !!token &&
      !!pcToken &&
      !!userProfile &&
      !!userId &&
      !!userName &&
      !!HSMs &&
      !!config.application.client.clientID &&
      !!actData;
  else
    logged =
      !!token && !!userProfile && !!userId && !!userName && !!HSMs && !!config.application.client.clientID && !!actData;

  if (pathname.includes('ghistory') && !logged) {
    localStorage.setItem('ghURL', pathname);
  }

  if (pathname.includes('widgettimedHSM') && !logged) {
    localStorage.setItem('scheduled', true);
  }

  if (pathname.includes('screenHSM') && !logged) {
    localStorage.setItem('widget', true);
  }

  return logged;
}

export function routingHistory() {
  const params = window.location.href.split('/');
  const ghURL = localStorage.getItem('ghURL');
  localStorage.removeItem('ghURL');

  if (!!ghURL) return { ghShow: false, ghURL };
  if (!ghURL) return { ghShow: true };
}

export function getUser() {
  if (localStorage.getItem('token')) {
    const user = {
      token: localStorage.getItem('token'),
      number: localStorage.getItem('number'),
      interactionId: localStorage.getItem('iid'),
      agentJwt: localStorage.getItem('pcToken'),
      origin: localStorage.getItem('origin'),
    };

    return user;
  } else {
    return false;
  }
}

// ----------------------------------------------------- //

// ---------------------- Utilities -------------------- //

export function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

  var regex = new RegExp('[\\#&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.hash);

  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getRescue(serverOnline) {
  if (serverOnline) {
    alert('Arquivo de configuração não encontrado');
  }
  alert('Servidor encontra-se fora do ar');
}

export function checkDate(dateToCheck) {
  let checker = new Date(dateToCheck).getTime();
  if (checker < 1664582400000) {
    return false;
  }
  if (checker >= 1664582399999) {
    return true;
  }
}

export function maxDays(from, to, threshold) {
  let date1 = new Date(from);
  let date2 = new Date(to);
  let timeDif = date2.getTime() - 1000 * 60 - date1.getTime();
  let inDays = timeDif / (1000 * 3600 * 24);
  // console.log(inDays);
  if (inDays >= threshold) {
    return false;
  }
  if (inDays < threshold) {
    return true;
  }
}
// ----------------------------------------------------- //

// -------------------- Genesys Call ------------------- //

export async function getConfig() {
  let baseURL = api.defaults.baseURL;
  const url = baseURL?.includes('brasilseg') || baseURL?.includes('vonage') ? '/newconfig' : '/config';

  if (defineLoginGenesys())
    return await api
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        config = res.data;
        let nameModify = config.application.client.baseName;
        redirect += !!nameModify ? nameModify : '/';
        auth_URL = `https://login.mypurecloud.com/oauth/authorize?client_id=${res.data.application.client.clientID}&response_type=token&redirect_uri=${redirect}`;
      })
      .catch((err) => toast.error((err.response && err.response.data.msg) || 'Configurações não encontradas'));
  else
    return await api
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        config = res.data;
      });
}
// ----------------------------------------------------- //

export function defineLoginGenesys() {
  let _url = process.env.REACT_APP_APIURL;
  let genesysLoginArr = [
    'https://vvjdev.lwespresso.com',
    'https://via.lwespresso.com/',
    'https://devmultilaser.espressolw.com/',
    'https://multitake.espressolw.com/',
    'https://devbrasilseg.espressolw.com',
    'https://brasilseg.espressolw.com',
  ];
  let isGenesysLogin = genesysLoginArr.find((i) => i == _url);
  return !!isGenesysLogin;
}
