import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState, useEffect } from "react";

export default function SetHoliday({
  holidays,
  mapingHoliday,
  setMapingHoliday,
  outerIndex,
  configuration,
}) {
  const [loadFlag, setLoadFlag] = useState(false);

  useEffect(() => {
    if (!!configuration && !loadFlag && holidays.length != 0) {
      let temp = [];
      for (let i = 0; i < configuration.holidays.length; i++) {
        temp.push(
          JSON.stringify(
            holidays.find((x) => x._id == configuration.holidays[i]._id)
          )
        );
      }
      let _mapingHoliday = mapingHoliday;
      _mapingHoliday[outerIndex] = temp;
      setMapingHoliday([..._mapingHoliday]);

      setLoadFlag(true);
    }
  }, [holidays, mapingHoliday, configuration]);

  const inputChange = (e, rowIndex) => {
    let _mapingHoliday = mapingHoliday;
    _mapingHoliday[outerIndex][rowIndex] = e.target.value;
    setMapingHoliday([..._mapingHoliday]);
  };

  function deleteRow(index) {
    let _mapingHoliday = mapingHoliday;
    if (_mapingHoliday[outerIndex].length > 0)
      _mapingHoliday[outerIndex].splice(index, 1);
    setMapingHoliday([..._mapingHoliday]);
  }

  function addRow() {
    let _mapingHoliday = mapingHoliday;
    if (!_mapingHoliday[outerIndex]) _mapingHoliday[outerIndex] = [];
    _mapingHoliday[outerIndex].push({});
    setMapingHoliday([..._mapingHoliday]);
  }

  return (
    <div className="d-flex flex-column w-100 align-items-center mt-2">
      <label className="defaultLabel"> Associar Feriados </label>

      {!!mapingHoliday[outerIndex] && mapingHoliday[outerIndex].length != 0
        ? mapingHoliday[outerIndex].map((c, rowIndex) => {
            return (
              <div
                key={rowIndex}
                className="d-flex w-75 align-items-center mt-3"
              >
                <select
                  onChange={(e) => inputChange(e, rowIndex)}
                  key={rowIndex}
                  className="select-bootstrap"
                  value={mapingHoliday[outerIndex][rowIndex]}
                >
                  <option value="" hidden>
                    {" "}
                    Selecione um Feriado{" "}
                  </option>
                  {holidays.map((holiday, idx) => (
                    <option value={JSON.stringify(holiday)} key={idx}>
                      {" "}
                      {holiday.name}{" "}
                    </option>
                  ))}
                </select>
                <IconButton onClick={() => deleteRow(rowIndex)}>
                  {" "}
                  <DeleteIcon sx={{ color: "white", color: "#e74c3c" }} />
                </IconButton>
              </div>
            );
          })
        : null}

      <Tooltip title="Adicionar">
        <IconButton
          onClick={() => addRow()}
          aria-label="Adicionar"
          sx={{
            backgroundColor: "#007bff",
            width: "28px",
            height: "28px",
            marginTop: "8px",
            ":hover": { backgroundColor: "#005bff" },
          }}
        >
          <AddIcon sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
    </div>
  );
}
