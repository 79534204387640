import timedHSMStyle from "./timedHSM.module.css";
import TimedHSMTable from "./timedTable";
import ActionBtn from "../../components/UI/actionbtn";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SearchIcon from "@mui/icons-material/Search";
import api from "../../services/api";
import { toast } from "react-toastify";

import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";

export default function TimedHSM() {
  const defaultSearch = {
    initDate: "",
    endingDate: "",
  };

  const [search, setSearch] = useState({ ...defaultSearch });
  const [timedHSMAPI, setTimedHSMAPI] = useState({});
  const [exec, setExec] = useState(true);
  const [warned, setWarned] = useState(false);
  const [flag, setFlag] = useState(true);

  // --------------------------------------------------------------- //
  useEffect(async () => {
    let payload = payloadConstructor();

    if (flag) {
      await api
        .post("api/espresso/scheduledcontacts/findbydate", payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0 && !warned) {
            toast.warn("Não há agendamentos realizados hoje");
            setWarned(true);
          }
          setTimedHSMAPI(res.data);
          return;
        })
        .catch((err) => {
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }
    setTimeout(() => {
      setExec(!exec);
    }, 15000);
  }, [exec]);
  // --------------------------------------------------------------- //

  function payloadConstructor() {
    let init = new Date(Date.now()).toISOString().slice(0, 10);
    let next = new Date(Date.now() + 86400000).toISOString().slice(0, 10);
    let payload = {
      initDate: init,
      endingDate: next,
      // "userName": localStorage.getItem("userName")
    };
    return payload;
  }

  async function loadTables(payload) {
    if (!payload) {
      setFlag(false);
      payload = {
        initDate: search.initDate + "T00:00:00.001Z",
        endingDate: search.endingDate + "T23:59:59.999Z",
      };
    }

    await api
      .post("api/espresso/scheduledcontacts/findbydate", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error("Erro #004: Sem resultado de busca");
        }
        setTimedHSMAPI(res.data);
        return;
      })
      .catch((err) => {
        toast.error("Erro #003: Falha ao acessar o banco de dados");
      });
    return;
  }

  const handleChange = (type, value) => {
    let _search = { ...search };
    _search[type] = value;
    setSearch({ ..._search });
  };

  const cleanSearch = () => {
    setSearch({ ...defaultSearch });
    setTimedHSMAPI({ ...[] });
    setFlag(true);
    let payload = payloadConstructor();
    loadTables(payload);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center pe-4">
        <p className="titlePage">HSM Agendados</p>
        <div className="d-flex align-items-center">
          <div
            className={`d-flex align-items-center ${timedHSMStyle.containerInput}`}
          >
            <label className="defaultLabel me-1">De:</label>
            <input
              className="input-bootstrap"
              type="date"
              value={search.initDate}
              id="searchdatefrom"
              onChange={(e) => handleChange("initDate", e.target.value)}
            />
          </div>
          <div
            className={`d-flex align-items-center mx-3 ${timedHSMStyle.containerInput}`}
          >
            <label className="defaultLabel me-1">Até:</label>
            <input
              className="input-bootstrap"
              type="date"
              value={search.endingDate}
              id="searchdateto"
              onChange={(e) => handleChange("endingDate", e.target.value)}
            />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="yellow" icon={<SearchOffIcon />} />
          </div>

          <div
            onClick={() => {
              cleanSearch();
            }}
          >
            <ActionBtn color="green" icon={<SearchIcon />} />
          </div>
        </div>
      </div>
      <div className="slBottomBox">
        <StyledEngineProvider injectFirst>
          <TimedHSMTable timedHSM={timedHSMAPI} />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
