import React from "react";
import Chart from "react-apexcharts";

export default function AreaChart({ series }) {
  const config = {
    options: {
      labels: ["Total de Envios"],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },

      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: (seriesName) => "Envios",
          },
        },
      },

      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 1,
          opacityTo: 0.7,
        },
      },

      dataLabels: {
        enabled: true,
      },

      stroke: {
        curve: "smooth",
      },

      xaxis: {
        type: "datetime",
      },

      yaxis: {
        opposite: true,
      },

      legend: {
        horizontalAlign: "left",
      },
    },
  };

  return (
    <Chart options={config.options} series={series} type="area" height="85%" />
  );
}
