import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "../../../components/tables/table.css";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../services/api"
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import RegisterPageModal from "./modal"
import HolidayRegister from "./modalRegister"


export default function HolidayTable({ apiData }) {

  const columns = [
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "specialDay", headerName: "Data", flex: 1, renderCell: (params) => (
        <a>
          {dateConversion(params.value)}
        </a>
      ),
    },
    {
      field: "recurring", headerName: "Recorrente", flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <Tooltip title={params.value ? 'Recorrente' : 'Apenas uma vez'}>
          <span className="table-cell-trucate">{params.value ? <CheckIcon sx={{ color: '#1abc9c' }} /> : <CloseIcon sx={{ color: "#e74c3c" }} />}</span>
        </Tooltip>
      )
    },
    { field: "message", headerName: "Mensagem", flex: 2 },
    { field: "labelStartingTime", headerName: "Hora Inicial", flex: 1, align: 'center', headerAlign: 'center' },
    { field: "labelEndingTime", headerName: "Hora Final", flex: 1, align: 'center', headerAlign: 'center' },
    {
      field: "workSchedule", headerName: "Dia Todo", flex: 1, align: 'center', headerAlign: 'center',
      renderCell: (params) => (
        <Tooltip title={params.value.closed ? 'Dia Todo' : 'Horário Específico'}>
          <span className="table-cell-trucate">{params.value.closed ? <CheckIcon sx={{ color: '#1abc9c' }} /> : <CloseIcon sx={{ color: "#e74c3c" }} />}</span>
        </Tooltip>
      )
    },
    {
      field: "_id",
      headerName: "Alterações",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="inline_Alt">
          <div onClick={() => { }}>
            <RegisterPageModal
              modalSize={{ height: 60, width: 35 }}
              iconButton={true}
              buttonString={<EditIcon style={{ color: "#1abc9c" }} />}
              boxName="Editar Schedule"
              styleButton={{ bgColor: "#bdc3c7", padding: 1.5 }}
            >
              <HolidayRegister _id={params.value} />
            </RegisterPageModal>
          </div>
          <IconButton sx={{ bgColor: "#bdc3c7", padding: 1.5, color: "#e74c3c" }} onClick={() => { dellDate(params.value); }}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  async function dellDate(_id) {
    if (window.confirm("Deseja mesmo Excluir ?")) {
      await api
        .delete(
          `api/espresso/holidays?id=${_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          //Retornando mensagem de sucesso mesmo sem confirmar a resposta.
          toast.success("Deletando Data Aguarde!");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          toast.error(
            (err.response && err.response.data.msg) || "Ocorreu um erro"
          );
        });
    }
  }

  const dateConversion = (dateToConvert) => {
    let browserSpecific = ''
    if(new Date(new Date(dateToConvert).getTime() + (3 * 60 * 60 * 1000)).toLocaleString().length === 20){
      browserSpecific = new Date(new Date(dateToConvert).getTime() + (3 * 60 * 60 * 1000)).toLocaleString().split(',')[0]
    }
    if(new Date(new Date(dateToConvert).getTime() + (3 * 60 * 60 * 1000)).toLocaleString().length === 19){
      browserSpecific = new Date(new Date(dateToConvert).getTime() + (3 * 60 * 60 * 1000)).toLocaleString().split(' ')[0]
    }
    return browserSpecific
  }

  const CustomToolbar = ({ setFilterButtonEl }) => (
    <GridToolbarContainer>
      <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
  );
  var rows = [];

  for (let i = 0; i < apiData.length; i++) {
    rows.push({
      id: i + 1,
      labelStartingTime: apiData[i].workSchedule.labelStartingTime,
      labelEndingTime: apiData[i].workSchedule.labelEndingTime,
      ...apiData[i],
    });
  }

  return (
    <div style={{ height: '110%', width: "98%" }}>
      <DataGrid
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createDate", sort: "desc" }],
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
      />
    </div>
  );
}
