import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import { withStyles } from "@material-ui/core";
import CircularLoading from "../loadModules/circularLoading"

const columns = [
  {
    field: "_id",
    headerName: "Data",
    type: "date",
    flex: 1,
    renderCell: (params) => (
      <div>{params.value.split("-").reverse().join("/").toString()}</div>
    ),
  },
  {
    field: "Iterações",
    headerName: "Iterações",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Conversas",
    headerName: "Conversas",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
];

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarExport csvOptions={{ delimiter: ";" }} printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);

const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-panelWrapper": {
      width: "100px",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-toolbarContainer": {
      minHeight: "30px",
      maxHeight: "30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-root": {
      marginLeft: "-2vh",
    }
  },
})(DataGrid);

export default function CdrLogTable({ retornoAPI, loading }) {
  var rows = [];

  if (retornoAPI.length !== 0) {
    for (let i = 0; i < retornoAPI.length; i++) {
      rows.push({ id: i + 1, ...retornoAPI[i] });
    }
  }

  return loading ?
    (<div style={{ height: '110%' }}>
      <StyledDataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
      />
    </div>) : (<div style={{ width: '96%', height: '110%' }}><CircularLoading/></div>)
}
