import * as React from "react";
import {
  DataGrid,
  ptBR,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./table.css";
import CircleIcon from "@mui/icons-material/Circle";
import CircularLoading from "../loadModules/circularLoading";

const columns = [
  {
    field: "queue",
    headerName: "Fila",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "QtdBolsao",
    headerName: "Quantidade Bolsão",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "Notificado",
    headerName: "Notificado",
    flex: 1,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "status",
    headerName: "Status Schedule",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => (
      <div>
        {params.value === "closed" ? (
          <p className="rowAlign">
            <CircleIcon sx={{ color: "#E74C3C" }} /> Fechado
          </p>
        ) : (
          <p className="rowAlign">
            <CircleIcon sx={{ color: "#1ABC9C" }} /> Aberto
          </p>
        )}
      </div>
    ),
  },
];

export default function messagesListTable({ tableInfo, loaded }) {
  var rows = [];
  if (tableInfo.length !== 0) {
    for (let i = 0; i < tableInfo.length; i++) {
      rows.push({ id: i + 1, ...tableInfo[i] });
    }
  }

  return tableInfo.length === 0 && loaded === false ? (<CircularLoading/>) : (
    <div style={{ height: "100%", width: "106%" }}>
      <DataGrid
        disableSelectionOnClick
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns}
        hideFooter={true}
      />
    </div>
  );
}
